
export const ROUTES = {
  DASHBOARD: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgotPassword',
  CREATE_NEW_PASSWORD: '/createPassword',
  CHANGE_PASSWORD: '/changePassword',
  USER_MANAGEMENT: '/userManagement',
  BRAND_MANAGEMENT: '/brands',
  CAMPAIGN_MANAGEMENT: '/campaign-management',
  ADMIN_USERS: '/adminUsers',
  ROLES_AND_RIGHTS: '/roles',
  SUBSCRIPTION_MGT: '/subscription',
  AUDIT_TRAILS: '/audit-trails',
  //VERIFY_NEW_USER_DETAILS: '/verifyNewUserDetails',
  USER_DETAILS: "/userManagement/details/",
  VERIFY_NEW_USER_DETAILS: 'details',
  ///userManagement/details/
  EDIT: '/edit',
  ADD: '/create',
  CREATE_INFLUENCER: '/createInfluencer',
  EDIT_INFLUENCER: '/editInfluencer',
  SIGN_UP: '/signup',
  VIEW: '/view',
  CREATE_CAMPAIGN: "/createCampaign",
  MY_CAMPAIGN: "/myCampaign",
  EDIT_CAMPAIGN: "/editCampaign",
  TEST: '/test',
  COMPANY_ADD: '/company/add',
  COMPANY_EDITS: '/company/edit',
  CAMPAIGN_STEP1: "campaign/type",
  CAMPAIGN_STEP2: "campaign/sub-type",
  CAMPAIGN_STEP3: "campaign/info",
  CAMPAIGN_STEP4: "campaign/budget-target",
  CAMPAIGN_STEP5: "campaign/dates",
  CAMPAIGN_STEP6: "campaign/profile-screener",
  CAMPAIGN_STEP7: "campaign/review",
  CAMPAIGN_REVIEW: "campaign/submit-review",
  CAMPAIGNS: "campaigns",
  CAMPAIGNS_DETAILS: "campaign/details",
  SEARCH_INFLUENCER: "campaign/search-influencer",
  BRANDS_FOR_COMPANY: '/company-brands',
  ADD_BRANDS_FOR_COMPANY: '/company-brands/add',
  COMPANY_EDIT: '/company-brands/add',
  MY_CAMPAIGNS: "my-campaigns",
  MY_CAMPAIGN_INFLUENCER: "my-campaigns/influencer",
  MY_CAMPAIGN_APPROVED_INFLUENCER: "my-campaigns/approved-influencer",
  COMPANY_SIGNUP: 'company-signup',
  USER_MANAGEMENT_DETAILS: '/userManagement/details',
  COMPANIES: "companies",
  SEARCH_INFLUENCER_BY_COMPANY: "search-Influencers",
  COMPANY_DETAILS: "company/details",
  CAMPAIGN_DETAILS: "campaign/details",
  COMING_SOON: "coming-soon",
  RESET_PASSWORD: "reset-password",
  COMPANY_PROFILE_EDIT: "company/profile",
  PRIVACY_POLICY: "privacy-policy",
  INFLUENCER_LISTING: "dashboard/listing",
  PARTIAL_CAMPAIGN_DETAILS: "campaign/partial/details",
  SEND_MORE_INFLUENCER: "campaign/send-more-influencer",
  VERIFY_OTP: "verify",
  CREATOR_MANAGEMENT: "creator-management",
  CREATOR_CONTENT_LIST: "creator-content",
  USER_BRANDS_LIST: "user-brands"
}
