import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, CardMedia, Checkbox, Chip, Grid, Stack, Typography } from '@mui/material';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { invitedInfluencerBySearch } from '../../pages/campaigns/action';
import { updateCampaign } from '../../pages/campaigns/campaignSlice';
import { ROUTES } from '../../utils/routes';

const SearchedInfluencer = () => {
    const dispatch = useDispatch();
    const { searchedInfluencers, searchedInfluencersInvitedIds, searchedInfluencersCheckboxIds, invitedInfluencers } = useSelector((state) => state.campaignSlice);

    useEffect(() => {
        //dispatch(getInfluencerProfilesSearch());
    }, [])
    return (
        searchedInfluencers?.length > 0 && searchedInfluencers?.filter((v) => !invitedInfluencers?.includes(v.id))?.map((v, i) =>
            <Box key={i}
                p={3}
                mb={2}
                sx={{
                    backgroundColor: '#f7f7f7',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.2)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                }}>
                <Grid container>
                    {/* Left side: Image */}
                    <Grid item xs={3} lg={3} md={3} sm={3} className='d-flex align-items-start' gap={1.5}>
                        <Checkbox
                            size='small'
                            checked={searchedInfluencersCheckboxIds.includes(v.id)}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    dispatch(updateCampaign({ searchedInfluencersCheckboxIds: [...searchedInfluencersCheckboxIds, v.id], listType: 1 }));
                                } else {
                                    dispatch(updateCampaign({ searchedInfluencersCheckboxIds: searchedInfluencersCheckboxIds.filter((d) => d !== v.id) }));
                                }

                            }}
                            key={i}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        <CardMedia
                            component="img"
                            image={v.picture ? v.picture : "/profile1.jpg"} // Replace with the actual image URL
                            alt={v.name}
                            style={{ borderRadius: '10px', width: '140px', height: "140px", border: '1px solid rgba(170, 170, 170, 0.5)' }}
                        />
                    </Grid>
                    {/* Right side: Data */}
                    <Grid item lg={9} md={9} sm={9}>
                        <Box>
                            <Typography className="text-capitalize d-flex align-items-center" variant="h4" component="h4" gap={2}>
                                {v.name}
                                <Typography className="d-flex align-items-center" gap={0.5}>
                                    <FacebookOutlinedIcon size="small" color='primary' />
                                    <InstagramIcon size="small" color='error' />
                                </Typography>

                            </Typography>
                            <Grid container p={0}  >

                                <Grid item xs={9} lg={9} md={9} sm={9}>
                                    <Grid item xs={12} lg={12} md={12} sm={12} py={2} gap={1} className='d-flex flex-wrap'>
                                        {v.profileNiche && JSON.parse(v.profileNiche)?.map((v) => <Chip label={v.option} key={v.option} color="primary" size="small" />)}
                                    </Grid>
                                    <Stack className='screenDetails' direction="row" mb={1} spacing={4} rowGap={1}>
                                        <Typography variant="h4" className='fs-5'>
                                            <label>Followers: </label>
                                            <span>{v.followers || "N/A"}
                                                {/* <span>{(v.followers != null ? v.followers : "") + (v.influencerType ? " (" + v.influencerType + ")" : "")}
                                                {!v.followers && !v.influencerType ? "N/A" : ""} */}
                                            </span>
                                        </Typography>
                                        <Typography variant="h4" className='fs-5'>
                                            <label>Location: </label>
                                            <span>{JSON.parse(v.location)?.toString() || "N/A"}</span>
                                        </Typography>
                                    </Stack>
                                    <Stack className='screenDetails' direction="row" spacing={1}>
                                        <Typography variant="h4" className='fs-5'>
                                            <label>Brands Endorse: </label>
                                            <span>N/A</span>
                                        </Typography>
                                    </Stack>

                                </Grid>
                                <Grid item xs={3} lg={3} md={3} sm={3}>
                                    <Stack spacing={1}>
                                        <button className='btnPrimary' onClick={() => window.open(`${ROUTES.USER_DETAILS}${v.id}`, "_blank")} target='_blank'>View Details</button>
                                        <button className='btnPrimary' onClick={() => {
                                            dispatch(updateCampaign({ invitedInfluencers: [v.id], searchedInfluencersInvitedIds: [...searchedInfluencersInvitedIds, v.id] }));
                                            dispatch(invitedInfluencerBySearch());
                                            //dispatch(getInfluencerProfilesSearch());
                                            // if (sugType !== "create") {
                                            //     dispatch(getInfluencerProfilesSuggestions());
                                            // } else {
                                            //     dispatch(inviteInfluencerByCampaign());
                                            // }
                                            //
                                            //
                                        }}
                                        >
                                            Send Invitation
                                        </button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid >
                </Grid >
            </Box >
        )

    );
};

export default SearchedInfluencer;
