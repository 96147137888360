import {
    Search
} from "@mui/icons-material";
import { Box, Button, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Form, Formik } from 'formik';
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SearchInput from "../../../components/inputs/searchInput";
import { AGE_GROUP, citiesInIndia } from "../../../utils/constant";
import { getInfluencerProfilesSuggestions } from "../action";
import { updateCampaign } from "../campaignSlice";
const FilterForSendMore = ({ setfilterState }) => {

    const { niches, influencerType, searchedInfluencers } = useSelector((state) => state.commonSlice);
    const { formData } = useSelector((state) => state.campaignSlice);

    const [nichesData, setNiches] = useState([]);
    const [creatorLocation, setCreatorLocation] = useState([]);

    const { id } = useParams();

    const [audienceLocation, setAudienceLocation] = useState([]);

    const dispatch = useDispatch();

    const initialValue = {
        niches: [],
        creatorLocation: [],
        influencerType: [],
        audienceLocation: [],
        creatorGender: "male",
        audienceGender: "male",
        nichesSearch: "",
        creatorLocationSearch: "",
        audienceLocationSearch: [],
        age: []
    }

    const [values, setValues] = useState(initialValue);
    useEffect(() => {
        setNiches(niches)
        setCreatorLocation(citiesInIndia);
        setAudienceLocation(citiesInIndia);
        dispatch(updateCampaign({ formData: { ...formData, campaignId: id } }))
        dispatch(getInfluencerProfilesSuggestions());
        //dispatch(getInfluencerProfilesSearch());
    }, [niches]);



    return <Grid container  >


        <Formik
            initialValues={values}
            enableReinitialize={true}
            //validateOnChange={true}
            // validationSchema={Schema.CampaignInfo}
            onSubmit={(values, { setSubmitting }) => {
                let filters = {
                    createrGender: values?.creatorGender,
                    location: values?.creatorLocation?.toString(),
                    influencerType: values?.influencerType?.toString(),
                    categories: values?.niches?.toString(),
                }
                dispatch(updateCampaign({ advanceFilter: filters }));
                dispatch(getInfluencerProfilesSuggestions());
                setValues(values);
                setfilterState(false);
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                dirty,
            }) => {
                return (
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}>
                        <Grid container>

                            <Grid item xs={8} lg={8} md={8} sm={8} bgRight={1} pl={4} rowGap={4} >
                                <Grid item xs={12} lg={12} md={12} sm={12} mb={2}>
                                    <Typography variant="h4" component="h4" >Advance Filter</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12} >
                                    <Typography variant="subtitle" component="subtitle" >Creator</Typography>
                                </Grid>
                                <Grid container gap={2}  >
                                    <Grid item xs={7} lg={7} md={7} sm={7} mt={2} border={"1px solid #EEEEEE"} p={1} >
                                        <Typography variant="subtitle2" gutterBottom>
                                            Creator Category
                                        </Typography>
                                        <SearchInput
                                            className="inputSearch p-0"
                                            value={values.nichesSearch}
                                            placeholder={"Search here..."}
                                            handleSearch={(e) => {
                                                if (e.target.value) {
                                                    setNiches(niches?.filter((v) => v.toLowerCase().includes(e.target.value)));
                                                }
                                                handleChange({
                                                    target: {
                                                        name: "nichesSearch",
                                                        value: e.target.value
                                                    }
                                                })
                                            }}
                                            clearSearch={() => { }}
                                            startAdornment={
                                                <InputAdornment className="overIcon " position="start"
                                                    sx={{
                                                        maxHeight: 'none',
                                                        height: '38px',
                                                        minWidth: '38px',
                                                        marginRight: '0px'
                                                    }}
                                                >
                                                    <Search />
                                                </InputAdornment>
                                            }
                                        />
                                        <Box
                                            style={{
                                                maxHeight: 150,
                                                overflowY: 'auto',
                                                overflowX: 'hidden',

                                            }}
                                        >
                                            <FormGroup sx={{
                                                gap: 1,
                                                mt: 2,

                                            }}>
                                                {nichesData?.map((v, i) => (
                                                    <FormControlLabel
                                                        key={i}
                                                        control={<Checkbox checked={values.niches.includes(v)} onChange={handleChange} name="niches" value={v} />}
                                                        label={v}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={4} lg={4} md={4} sm={4} mt={2} border={"1px solid #EEEEEE"} p={1} >
                                        <Typography variant="subtitle2" gutterBottom>
                                            Creator Type
                                        </Typography>

                                        <FormGroup sx={{ gap: 1, mt: 2 }}>
                                            {influencerType?.map((v, i) => <FormControlLabel key={i} control={<Checkbox checked={values.influencerType.includes(v)} onChange={handleChange} name="influencerType" value={v} />} label={v} />)}
                                        </FormGroup>

                                    </Grid>
                                    <Grid item xs={1} lg={1} md={1} sm={1}></Grid>
                                </Grid>

                                <Grid container gap={2}  >
                                    <Grid item xs={12} lg={12} md={12} sm={12} border={"1px solid #EEEEEE"} p={1}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Creator Gender
                                        </Typography>
                                        <Stack direction="row" gap={4}>
                                            <button className={values.creatorGender === "male" ? 'btnPrimary' : 'btnSecondary'} onClick={(e) => {
                                                e.preventDefault();
                                                handleChange({
                                                    target: {
                                                        name: "creatorGender",
                                                        value: "male"
                                                    }
                                                })
                                            }}>
                                                Male
                                            </button>
                                            <button className={values.creatorGender === "female" ? 'btnPrimary' : 'btnSecondary'} onClick={(e) => {
                                                e.preventDefault();
                                                handleChange({
                                                    target: {
                                                        name: "creatorGender",
                                                        value: "female"
                                                    }
                                                })
                                            }}>
                                                Female
                                            </button>
                                            <button className={values.creatorGender === "LGBTQ" ? 'btnPrimary' : 'btnSecondary'} onClick={(e) => {
                                                e.preventDefault();
                                                handleChange({
                                                    target: {
                                                        name: "creatorGender",
                                                        value: "LGBTQ"
                                                    }
                                                })
                                            }} >
                                                LGBTQ+
                                            </button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container gap={2}  >
                                    <Grid item xs={6} lg={6} md={6} sm={6} mt={2} border={"1px solid #EEEEEE"} p={1}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Creator Location
                                        </Typography>
                                        <SearchInput
                                            className="inputSearch p-0"
                                            value={values.creatorLocationSearch}
                                            placeholder={"Search here..."}
                                            handleSearch={(e) => {
                                                if (e.target.value) {
                                                    setCreatorLocation(citiesInIndia?.filter((v) => v.toLowerCase().includes(e.target.value)));
                                                } else {
                                                    setCreatorLocation(citiesInIndia);
                                                }
                                                handleChange({
                                                    target: {
                                                        name: "creatorLocationSearch",
                                                        value: e.target.value
                                                    }
                                                })
                                            }}
                                            clearSearch={() => { }}
                                            startAdornment={
                                                <InputAdornment className="overIcon " position="start"
                                                    sx={{
                                                        maxHeight: 'none',
                                                        height: '38px',
                                                        minWidth: '38px',
                                                        marginRight: '0px'
                                                    }}
                                                >
                                                    <Search />
                                                </InputAdornment>
                                            }
                                        />
                                        <Box
                                            style={{
                                                maxHeight: 150,
                                                overflowY: 'auto',
                                                overflowX: 'hidden',

                                            }}
                                        >
                                            <FormGroup sx={{ gap: 1, mt: 2 }}>
                                                {creatorLocation?.map((v, i) => <FormControlLabel key={i} control={<Checkbox checked={values.creatorLocation.includes(v)} onChange={handleChange} name="creatorLocation" value={v} />} label={v} />)}
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={4} lg={4} md={4} sm={4} bgcolor={"#E2EAF3"}>
                                <Grid item xs={12} lg={12} md={12} sm={12} pl={4} mt={2} mb={2}>
                                    <Typography variant="h4" component="h4" >Audience Filter</Typography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} sm={12} mr={2} bgRight={1} pl={2}  >
                                    <Grid item xs={12} lg={12} md={12} sm={12} mt={2} border={"1px solid #EEEEEE"} bgcolor={"#FFFFFF"} p={1}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Audience Location
                                        </Typography>
                                        <SearchInput
                                            className="inputSearch p-0"
                                            value={values.audienceLocationSearch}
                                            placeholder={"Search here..."}
                                            handleSearch={(e) => {
                                                if (e.target.value) {
                                                    setAudienceLocation(citiesInIndia?.filter((v) => v.toLowerCase().includes(e.target.value)));
                                                } else {
                                                    setAudienceLocation(citiesInIndia);
                                                }
                                                handleChange({
                                                    target: {
                                                        name: "audienceLocationSearch",
                                                        value: e.target.value
                                                    }
                                                })
                                            }}
                                            clearSearch={() => { }}
                                            startAdornment={
                                                <InputAdornment className="overIcon " position="start"
                                                    sx={{
                                                        maxHeight: 'none',
                                                        height: '38px',
                                                        minWidth: '38px',
                                                        marginRight: '0px'
                                                    }}
                                                >
                                                    <Search />
                                                </InputAdornment>
                                            }
                                        />
                                        <Box
                                            style={{
                                                maxHeight: 150,
                                                overflowY: 'auto',
                                                overflowX: 'hidden',

                                            }}
                                        >
                                            <FormGroup sx={{ gap: 1, mt: 2 }}>
                                                {audienceLocation?.map((v, i) => <FormControlLabel key={i} control={<Checkbox checked={values.audienceLocation.includes(v)} onChange={handleChange} name="audienceLocation" value={v} />} label={v} />)}
                                            </FormGroup>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12} border={"1px solid #EEEEEE"} bgcolor={"#FFFFFF"} mt={2} p={1}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Audience Gender
                                        </Typography>
                                        <Stack direction="row" gap={2}>
                                            <Button variant="contained" size="small" color={values.audienceGender === "male" ? 'info' : 'secondary'} onClick={(e) => {
                                                e.preventDefault()
                                                handleChange({
                                                    target: {
                                                        name: "audienceGender",
                                                        value: "male"
                                                    }
                                                })
                                            }}>
                                                Male
                                            </Button>

                                            <Button variant="contained" size="small" color={values.audienceGender === "female" ? 'info' : 'secondary'} onClick={(e) => {
                                                e.preventDefault();
                                                handleChange({
                                                    target: {
                                                        name: "audienceGender",
                                                        value: "female"
                                                    }
                                                })
                                            }}>
                                                Female
                                            </Button>
                                            <Button variant="contained" size="small" color={values.audienceGender === "LGBTQ" ? 'info' : 'secondary'} onClick={(e) => {
                                                e.preventDefault();
                                                handleChange({
                                                    target: {
                                                        name: "audienceGender",
                                                        value: "LGBTQ"
                                                    }
                                                })
                                            }}>
                                                LGBTQ+
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12} mt={2} border={"1px solid #EEEEEE"} bgcolor={"#FFFFFF"} p={1}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Audience age range
                                        </Typography>

                                        <FormGroup >
                                            {AGE_GROUP.map((v, i) => {
                                                return <FormControlLabel key={i} control={<Checkbox name="age" value={v} checked={values.age.includes(v)} onChange={handleChange} />} label={v} />
                                            })}
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} lg={12} md={12} sm={12} mt={4}>
                                        <Stack direction="row" gap={4}>
                                            <button className='btnSecondary' onClick={() => {
                                                setValues(initialValue);
                                                setfilterState(false);
                                            }}>
                                                Clear Filter
                                            </button>
                                            <button className='btnPrimary' type="submit">
                                                Apply Filter
                                            </button>

                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form >
                );
            }}
        </Formik >

    </Grid>
}
export default memo(FilterForSendMore);