import {
    Box,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useEffect, useState } from "react";
//import SelectPerPage from "../../components/select/paginationSelect";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import DetailsButtons from "../../../components/buttons";
import ScriptListing from '../../../components/dialog/ScriptListing';
import { BASE_URL, CAMPAIGN_TYPES, SOCKET_EVENT } from "../../../utils/constant";
import { endpoints } from "../../../utils/endpoints";
import { getCampaignInfluencersListNew } from "../action";
import { updateCampaign } from '../campaignSlice';



function EnhancedTableHead(props) {
    const { order, orderBy, type } = props;

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Influencer Name",
            type: "",
        },
        {
            id: "productStatus",
            numeric: false,
            disablePadding: true,
            label: "Product Status",
            type: "",
        },
        {
            id: "conceptScript",
            numeric: false,
            disablePadding: true,
            label: "Concept Script",
            type: "",
        },
        {
            id: "conceptReview",
            numeric: false,
            disablePadding: true,
            label: "Content Review",
            type: "",
        },

        {
            id: "finalPublish",
            numeric: false,
            disablePadding: true,
            label: "Final Publish",
            type: "",
        },
        {
            id: "status",
            numeric: false,
            disablePadding: true,
            label: "Status",
            type: "",
        },
        {
            id: "action",
            numeric: false,
            disablePadding: true,
            label: "Action",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {headCells.filter((v) => {
                    // Filter out "Product Status" column if the type is not "barter"
                    if (type !== CAMPAIGN_TYPES.BARTER && v.id === "productStatus") {
                        return false;
                    }
                    return true;
                }).map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"left"}
                            padding={headCell.disablePadding ? "none" : "normal"}

                        >
                            <TableSortLabel
                            >
                                {headCell.label}

                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

function Tableheader(props) {
    const { order, orderBy } = props;

    const headCells = [
        {
            id: "name",
            numeric: false,
            disablePadding: true,
            label: "Influencer Name",
            type: "",
        },
        {
            id: "conceptNote",
            numeric: false,
            disablePadding: true,
            label: "Product Shipped",
            type: "",
        },
        {
            id: "conceptScript",
            numeric: false,
            disablePadding: true,
            label: "Product Delivered",
            type: "",
        },
    ];

    // headCell.type == '' || ([headCell.type].includes(subType)) ?
    return (
        <TableHead className="hideActionSort">
            <TableRow>
                {headCells.map((headCell) => {
                    return (
                        <TableCell
                            key={headCell?.id}
                            align={"right"}

                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                            //onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

export default function ApprovedInfluencers() {


    const [openModal, setOpenmodel] = useState(false);

    const [influencer, setInfluencer] = useState({});
    const state = useLocation();

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        campaignInfluencersList, listing
    } = useSelector((state) => state.campaignSlice)
    const socket = io(BASE_URL + endpoints.ims, {
        path: '/stream',
        transports: ['websocket']
    });
    useEffect(() => {
        dispatch(getCampaignInfluencersListNew(id));
    }, []);


    useEffect(() => {
        socket.on(SOCKET_EVENT.CONTENT_POST, (data) => {

            if (id === data?.id)
                dispatch(getCampaignInfluencersListNew(id, data?.campaignContentData));
        });
    }, [socket]);



    return (
        <>
            <Stack className='mainTitleBtn d-flex flex-wrap flex-row justify-content-between gap-3' mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography variant="h1" className="page-title">
                        {state?.state?.name || campaignInfluencersList?.name}
                    </Typography>
                    <Stack
                        className="backText"
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/my-campaigns/influencer/" + id)}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"} >
                            Back
                        </Typography>
                    </Stack>

                </Stack>
                <DetailsButtons />
            </Stack>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                {/* <Stack direction="row" p={3} gap={1} alignItems="center" justifyContent="flex-end">
                        <Select
                            // value={filters?.postedOn || ""}
                            name="postedOn"
                            // onChange={(e) => dispatch(updateCampaign({ filters: { ...filters, postedOn: e.target.value } }))}
                            displayEmpty
                            defaultValue=""
                            className="upperCase"
                        >
                            <MenuItem disabled value="">
                                All Status
                            </MenuItem>
                            {[REVIEW, PENDING, BID_ACCEPT].map((v) => (
                                <MenuItem className="upperCase" key={v} value={v}>
                                    {v}
                                </MenuItem>
                            ))}
                        </Select>



                        <Tooltip title="Refresh listing">
                            {/* <RefreshIcon
                                toolTip="Refresh page"
                                size="small"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    dispatch(getCampaignInfluencersListNew(id));
                                }}
                            /> </Tooltip>
                        
                    </Stack> */}
                <Box className="search_bar">
                </Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 540 }}>
                        <Table
                            stickyHeader aria-label="sticky table"
                        >
                            <EnhancedTableHead
                                type={campaignInfluencersList?.type}
                                order={"asc"}
                                orderBy={"name"}
                                //onSelectAllClick={handleSelectAllClick}
                                onRequestSort={() => { }}
                                numSelected={0}
                                onSelectAllClick={function (

                                ) {
                                    throw new Error("Function not implemented.");
                                }}
                            //order={"desc"}
                            />
                            <TableBody>
                                {campaignInfluencersList?.campaignContent?.length > 0 ? (
                                    campaignInfluencersList?.campaignContent?.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row?.id}
                                                className={"tableRow"}
                                                style={{ userSelect: "text" }}
                                            >
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.influencer?.name || "N/A"}
                                                </TableCell>
                                                {CAMPAIGN_TYPES.BARTER === campaignInfluencersList?.type &&
                                                    <TableCell component="th" scope="row" padding="none">
                                                        {row?.productStatus || "N/A"}
                                                    </TableCell>
                                                }
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.scriptStatus || "N/A"}
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    {row?.postStatus || "N/A"}
                                                </TableCell>

                                                <TableCell align="left" className="single-line">
                                                    {row.publishStatus}
                                                </TableCell>

                                                <TableCell align="left">

                                                    <button className={row.invoiceStatus?.toLowerCase()}>{row.invoiceStatus}</button>
                                                </TableCell>

                                                <TableCell>
                                                    <div className="actBtnGrps">
                                                        <span className="viewIcon" onClick={() => {
                                                            setInfluencer(row.influencer);
                                                            dispatch(updateCampaign({ scriptDetails: row.campaignContentJourney, journeyId: row.id, address: row?.address, productDetails: { productStatus: row.productStatus, influencerid: row.influencer?.id, campaignId: campaignInfluencersList.id } }))
                                                            // setScriptDetails(row.campaignContentJourney)
                                                            setOpenmodel(true);
                                                        }}>View</span>
                                                    </div>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow colSpan={10}>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </TableContainer>
                </Paper>
            </Box>

            {openModal && <ScriptListing campaignId={id} influencer={influencer} openModal={openModal} setOpenmodel={setOpenmodel} />}

        </>
    );
}
