import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { dateForamt } from '../../utils/common';
import { RUPEES_SYMBOL, getDaysDifference } from '../../utils/constant';
import { getAllBrandForDropDown, getCompanyById } from '../brands/action';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const CompanyDetails = () => {

    const { id } = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { state } = useLocation();



    const [value, setValue] = React.useState(0);

    useEffect(() => {
        if (id) {
            dispatch(getCompanyById(id))
            dispatch(getAllBrandForDropDown(state.userId));
        }
    }, [id])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const { brand, brandsDropdown } = useSelector((state) => state.brandSlice);



    return (
        <>
            <Stack direction="row" alignItems="center" spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography variant="h1" className="page-title">
                        {brand?.companyName}
                    </Typography>
                    <Stack
                        className="backText"
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => { }}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"} onClick={() => navigate("/companies")}>
                            Back
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Box
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}
            >
                <div className='p-4'>
                    <div className='row'>
                        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12'>
                            <div className='upLevel d-flex flex-wrap align-items-start justify-content-start gap-3'>
                                <CardMedia
                                    component="img"
                                    sx={{ width: '110px', height: '110px', borderRadius: '10px', border: '1px solid rgba(170, 170, 170, 0.3)' }}
                                    image={brand?.logo}
                                    alt="Brand Image"
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography variant="subtitle1" >
                                        <span className="label">Company Name: </span>  {brand?.companyName}
                                    </Typography>
                                    <Typography variant="subtitle1"  >
                                        <span className="label">Contact Person:</span>   {brand?.contactPersonName}
                                    </Typography>
                                    <Typography variant="subtitle1" >
                                        <span className="label">Email ID:</span> {brand?.email}
                                    </Typography>
                                    <Typography variant="subtitle1" >
                                        <span className="label">Mobile:</span>  {brand?.phoneCountryCode}{brand?.phoneNumber}
                                    </Typography>
                                </Box>
                            </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 d-flex flex-wrap align-items-start justify-content-xxl-end justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start'>
                            <hr className='d-xxl-none d-xl-none d-lg-none d-md-none d-sm-block d-block pt-xxl-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-4 pt-4 mt-xxl-0 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-4' />
                            <Typography variant="subtitle1" gutterBottom>
                                <span className="label">Campaigns Running: </span>  {brand?.campaignCount}
                            </Typography>
                        </div>
                    </div>
                </div>
                <hr />
                <Grid container spacing={2} p={4}>
                    <Grid item xs={12} m={0} p={0}>
                        <Typography variant="h4" className='mb-3'>Company Basics</Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Industry Niche:</span>  {brand?.brandBasics?.niche?.toString()}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Brief Description of Products/Services</span>  <br /> {brand?.brandBasics?.description}
                        </Typography>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} p={4}>
                    <Grid item xs={12} m={0} p={0}>
                        <Typography variant="h4" className='mb-3'>Target Audience</Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Geographic Preferences (Regions/Countries):</span>  {brand?.targetAudience?.geoPreferences?.join(", ")}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Describe Your Ideal Customer:</span>  <br /> <span dangerouslySetInnerHTML={{ __html: brand?.targetAudience?.description || "N/A" }} ></span>
                        </Typography>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} p={4}>
                    <Grid item xs={12} m={0} p={0}>
                        <Typography variant="h4" className='mb-3'>Visual Company</Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} m={0} p={0}>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Primary Color:</span>  {brand?.branding?.primaryColor || "N/A"}
                        </Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} m={0} p={0}>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Secondary Color:</span>  {brand?.branding?.secondaryColor || "N/A"}
                        </Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={12} xs={12} m={0} p={0}>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Font Preferences:</span> {brand?.branding?.font || "N/A"}
                        </Typography>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} p={4}>
                    <Grid item xs={12} m={0} p={0}>
                        <Typography variant="h4" className='mb-3'>Social Media Presence</Typography>
                    </Grid>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12} m={0} p={0}>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Platform Used:</span>  {brand?.socialMedia?.youtube?.length > 0 && (
                                <IconButton href={brand?.socialMedia?.youtube[0]?.url} target="_blank" rel="noopener">
                                    <YouTubeIcon color="error" />
                                </IconButton>
                            )}

                            {brand?.socialMedia?.facebook?.length > 0 && (
                                <IconButton href={brand?.socialMedia?.facebook[0]?.url} target="_blank" rel="noopener">
                                    <FacebookIcon color="info" />
                                </IconButton>
                            )}
                            {brand?.socialMedia?.linkedin?.length > 0 && (
                                <IconButton href={brand?.socialMedia?.linkedin[0]?.url} target="_blank" rel="noopener">
                                    <LinkedInIcon color="info" />
                                </IconButton>
                            )}
                            {brand?.socialMedia?.instagram?.length > 0 && (
                                <IconButton href={brand?.socialMedia?.instagram[0]?.url} target="_blank" rel="noopener">
                                    <InstagramIcon color="error" />
                                </IconButton>
                            )}
                        </Typography>
                    </Grid>
                    {
                        brand?.socialMedia && Object.entries(brand?.socialMedia)?.map(([key, value]) => {
                            return value?.map((item) => {

                                return <Grid item xl={4} lg={4} md={12} sm={12} xs={12} m={0} p={0}>
                                    <Typography variant="subtitle1" style={{ wordWrap: 'break-word' }} gutterBottom>
                                        <span className="label">{key.charAt(0).toUpperCase() + key.slice(1)} UserName:</span>  {item.userName}<br></br>
                                        <span className="label" >{key.charAt(0).toUpperCase() + key.slice(1)} URL:</span>  {item.url}
                                    </Typography>
                                </Grid>
                            })
                        })
                    }
                    <Grid item xs={4} m={0} p={0}>
                        <Typography variant="subtitle1" gutterBottom>

                        </Typography>
                    </Grid>
                </Grid>
                <hr />
                <Grid container spacing={2} p={4}>
                    <Grid item xs={12} m={0} p={0}>
                        <Typography variant="h4" className='mb-3'>Company Values and Messaging</Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} m={0} p={0}>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Key Messages or Company Talking Points</span>  <br /> <span dangerouslySetInnerHTML={{ __html: brand?.brandValueAndMessaging?.messages || "N/A" }} ></span>
                        </Typography >
                    </Grid >
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} m={0} p={0}>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Tone of Voice and Communication Style</span>  <br /> <span dangerouslySetInnerHTML={{ __html: brand?.brandValueAndMessaging?.toneOfVoice || "N/A" }} ></span>
                        </Typography >
                    </Grid >
                </Grid >
                <hr />
                <Grid container spacing={2} p={4}>
                    <Grid item xs={12} m={0} p={0}>
                        <Typography variant="h4" className='mb-3'>Additional Information</Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            <span className="label">Key Messages or Company Talking Points</span>  <br />  <span dangerouslySetInnerHTML={{ __html: brand?.brandValueAndMessaging?.additionalInformation || "N/A" }} ></span>
                        </Typography >
                    </Grid >
                </Grid >
            </Box >
            <Box
                className='compnyDet'
                mt={3}
                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                    width: '100%'
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs className='w-100' value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab className='w-50 text-capitalize' label="Campaign Details" {...a11yProps(0)} style={{ maxWidth: 'none' }} />
                        <Tab className='w-50 text-capitalize' label="Brand Profile" {...a11yProps(1)} style={{ maxWidth: 'none' }} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0} p={0}>
                    <Paper sx={{ width: '100%', overflow: 'hidden', overflowY: 'auto' }}>
                        <TableContainer p={0} sx={{ maxHeight: 440, overflowY: 'auto' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Campaign Name</TableCell>
                                        <TableCell >Campaign Start Date</TableCell>
                                        <TableCell >Period</TableCell>
                                        <TableCell >Amount Invested</TableCell>
                                        <TableCell >Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {brand?.campaigns && brand?.campaigns.length > 0 ? brand?.campaigns.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell >{row.startDate ? dateForamt(row.startDate) : "N/A"}</TableCell>
                                            <TableCell >{getDaysDifference(row.startDate, row.endDate) + " Days" || 0}</TableCell>
                                            <TableCell >{RUPEES_SYMBOL + row.budget || 0}</TableCell>
                                            <TableCell ><RemoveRedEyeIcon className="viewIcon" size="small" onClick={() => navigate("/campaign/details/" + row.id)} /></TableCell>
                                        </TableRow>
                                    )) : <TableRow colSpan={10}>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Paper sx={{ width: '100%', height: "100%", }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Logo</TableCell>
                                        <TableCell >Brand Name</TableCell>
                                        <TableCell >Product Name</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {brandsDropdown?.length > 0 ? brandsDropdown?.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                <img src={row?.brandImage} alt="brandimage" width={60} />
                                            </TableCell>
                                            <TableCell > {row.brandName}</TableCell>
                                            <TableCell >{row.product?.map((v) => v?.name).toString()}</TableCell>
                                        </TableRow>
                                    )) : <TableRow colSpan={10}>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </CustomTabPanel>
            </Box>
        </>
    )
}

export default CompanyDetails