import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Checkbox, Chip, Grid, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AutoSuggestion from "../../../components/autocomplete";
import InviteInfulencerModel from "../../../components/dialog";
import ManualSearchModel from "../../../components/dialog/ManualSearch";

import SearchedInfluencer from '../../../components/card/SearchedInfluencer';
import Drawer from '../../../components/drawer';
import { USER_STATUS } from "../../../utils/constant";
import { getInfluencerProfilesSearch, invitedInfluencerBySearch } from '../action';
import { updateCampaign } from "../campaignSlice";
import FilterForSendMore from './filterForSendMore';
const SendMoreInfluencer = () => {
    const [openModal, setOpenmodel] = useState(false);
    const [open, setOpen] = useState(false);
    const [influencerNiche, setInfluencerNiche] = useState([]);
    const [filterState, setfilterState] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { advanceFilter, searchedInfluencers, searchedInfluencersCheckboxIds } = useSelector((state) => state.campaignSlice);
    const { niches } = useSelector((state) => state.commonSlice);

    return <>
        {/*  */}
        <Box display={open ? "none" : ""} >
            <Stack direction={"column"} gap={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography className="page-title" variant="h1">Send More Influencer</Typography>
                </Stack>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        transition: 'all .5s ease-in-out',
                        position: 'relative',
                        border: '1px solid rgba(170, 170, 170, 0.3)',
                        borderRadius: '12px',
                        boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                        overflow: 'hidden',
                    }}
                >
                    <Grid container >
                        <Grid item pl={5} mb={6} pb={2} xs={8} lg={8} md={8} sm={8}>
                            <Formik
                                initialValues={{ influencers: [] }}
                                enableReinitialize={true}
                                validateOnChange={true}
                                //validationSchema={Schema.Step4}
                                onSubmit={(values, { setSubmitting }) => {

                                    // let jsonString = JSON.stringify({ search: values?.influencers?.toString(), status: USER_STATUS.APPROVED });// campaignId: formData.campaignId
                                    // const encodedString = encodeURIComponent(jsonString);
                                    dispatch(updateCampaign({ advanceFilter: { ...advanceFilter, search: values?.influencers?.toString(), status: USER_STATUS.APPROVED } }));
                                    dispatch(getInfluencerProfilesSearch());
                                }}
                            >
                                {({
                                    errors,
                                    handleBlur,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    touched,
                                    values,
                                    setFieldValue,
                                    isValid,
                                    dirty,
                                }) => {

                                    return (
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                handleSubmit(e);
                                            }}
                                        >
                                            <Grid container >
                                                <Grid item xs={12} lg={12} md={12} sm={12}   >
                                                    <Stack className="searchBlockInflu" direction="row">
                                                        <Box className="searchText" style={{ width: 'calc(100% - 100px)' }}>
                                                            <AutoSuggestion
                                                                multiple={false}
                                                                disableClearable={false}
                                                                values={""}
                                                                //classes={{ listbox: classes.autocompleteListbox, option: classes.autocompleteOption }}
                                                                freeSolo={true}
                                                                className="auto-chips-cover"
                                                                label={""}
                                                                onChange={(_e, newValue) => {
                                                                    handleChange({
                                                                        target: { name: "influencers", value: newValue },
                                                                    });
                                                                }}
                                                                clearIcon={true}
                                                                handleClear={() => {
                                                                    handleChange({
                                                                        target: { name: "influencers", value: [] },
                                                                    });
                                                                    dispatch(updateCampaign({ advanceFilter: { ...advanceFilter, search: "", status: USER_STATUS.APPROVED } }));
                                                                    dispatch(getInfluencerProfilesSearch());
                                                                }}
                                                                onInputChange={(e) => {
                                                                    //setInfluencerNiche(() => nicha)
                                                                    if (e.target.value) {
                                                                        dispatch(updateCampaign({ nicha: influencerNiche }))
                                                                    } else {
                                                                        dispatch(updateCampaign({ nicha: influencerNiche?.filter((v) => ["Art & Creativity", "Parenting & Family", "Travel & Adventure", "Sports & Fitness", "Education & Learning"].includes(v)) }))
                                                                    }


                                                                }}
                                                                defaultValue={values?.influencers}
                                                                key={values?.influencers?.length}
                                                                limitTags={2}
                                                                id="multiple-limit-tags"
                                                                size="small"
                                                                name="search"
                                                                placeholder="Search any influencer here manually"
                                                                options={niches}
                                                                isOptionEqualToValue={(option, value) => {
                                                                    if (
                                                                        value === "" ||
                                                                        option === "" ||
                                                                        value === option
                                                                    )
                                                                        return option;
                                                                }}
                                                                getOptionLabel={(option) =>
                                                                    option || "no_option_found"
                                                                }

                                                                renderOption={(props, option) => {

                                                                    return (
                                                                        < >
                                                                            {props.id === "search-option-0" && <Grid item xs={12} lg={12} md={12} sm={12} mb={2}>
                                                                                <Typography component="h4" variant="h4" display="block" pt={0}>
                                                                                    Search Suggestion
                                                                                </Typography>
                                                                                <hr />
                                                                            </Grid>}

                                                                            <Grid sx={{ display: "flex", flexWrap: 'wrap' }} {...props}>
                                                                                <Chip

                                                                                    // avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                                                                    label={option}
                                                                                    color="default"
                                                                                />


                                                                            </Grid>

                                                                        </>
                                                                    );
                                                                }}

                                                            />

                                                        </Box>
                                                        <Box>
                                                            <button
                                                                className="searchBtn"
                                                                type="submit"
                                                            >
                                                                {/* <SearchIcon /> */}Generate ✨

                                                            </button>
                                                        </Box>
                                                    </Stack>


                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4} sm={4}>
                            <Stack direction="row" gap={4} mt={6} ml={30}>
                                <button
                                    className="btnSecondary"
                                    onClick={() => setfilterState(true)}
                                >
                                    Advance Filter <FilterAltIcon color="info" />

                                </button>

                            </Stack>
                        </Grid>
                        <hr />
                        <Grid container xs={12} lg={12} md={12} sm={12} p={5}>
                            <Grid item xs={8} lg={8} md={8} sm={8} mb={2}>

                                <Grid container rowGap={4} className='d-flex align-items-center justify-content-end' >
                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        <Typography variant="h4" component="h4">Influencer’s Profile Screener</Typography>
                                    </Grid>


                                </Grid>
                                <Grid container rowGap={4} className='d-flex align-items-center justify-content-end'>
                                    <Grid item xs={4} lg={4} md={4} sm={4}>

                                        {searchedInfluencers?.length > 0 && (
                                            <Typography py={3} >
                                                <Checkbox
                                                    size='small'
                                                    checked={searchedInfluencersCheckboxIds?.length === searchedInfluencers?.length}
                                                    onChange={(event) => {
                                                        let ids = [];
                                                        if (event.target.checked) {
                                                            ids = searchedInfluencers?.map((v) => v.id) || [];
                                                        } else {
                                                            ids = [];
                                                        }
                                                        dispatch(updateCampaign({ searchedInfluencersCheckboxIds: ids }));
                                                    }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />&nbsp;
                                                <Link href="#" className='text-decoration-none'>Invite all</Link>
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={5} lg={5} md={5} sm={5}></Grid>
                                    <Grid item xs={3} lg={3} md={3} sm={3} justifyContent="flex-end" alignContent={"right"} flexDirection={"right"}>

                                        {searchedInfluencersCheckboxIds?.length == searchedInfluencers?.length && searchedInfluencers?.length !== 0 && (
                                            <button className='btnPrimary' onClick={() => {
                                                dispatch(updateCampaign({ searchedInfluencersInvitedIds: searchedInfluencersCheckboxIds }));
                                                dispatch(invitedInfluencerBySearch());
                                            }}>
                                                Send Invitations
                                            </button>
                                        )}

                                    </Grid>

                                    <Grid item xs={12} lg={12} md={12} sm={12}>
                                        {(searchedInfluencers?.length === 0) && <Typography variant="h5" flexDirection={"right"}>{"We couldn't find any influencers that match your search..."}</Typography>}


                                    </Grid>
                                </Grid>
                                {/* {(userListing?.length === 0 && listType === 0) && <Typography variant="h5" flexDirection={"right"}>{"We couldn't find any influencers that match your search..."}</Typography>} */}

                                <Grid container>
                                    <Grid item xs={12} lg={12} md={12} sm={12}>

                                        <SearchedInfluencer />
                                    </Grid>
                                </Grid>


                            </Grid>



                        </Grid>
                        <hr />
                        <Grid item p={3} xs={12} lg={12} md={12} sm={12} container justifyContent="flex-end">
                            <Stack direction={"row"} spacing={1}>
                                <button className='btnSecondary'
                                    onClick={() => {
                                        navigate("/my-campaigns/influencer/468")
                                    }}
                                >
                                    Back
                                </button>


                            </Stack>
                        </Grid>

                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12}>
                        <InviteInfulencerModel openModal={openModal} setOpenmodel={setOpenmodel} />
                        <ManualSearchModel openModal={open} setOpenmodel={setOpen} />
                    </Grid>
                </Box>
            </Stack >
        </Box >
        <InviteInfulencerModel openModal={openModal} setOpenmodel={setOpenmodel} />
        <Drawer
            state={filterState}
            setState={setfilterState}
            className="filterDrawer"
            width="60%"
        >
            <FilterForSendMore setfilterState={setfilterState} />
        </Drawer>
    </>
}
export default SendMoreInfluencer;