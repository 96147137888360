import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Form from "../components/Form";
import CustomizedSnackbars from "../components/snackbar";
import { useGetUserQuery, useUpdateUserMutation } from "../redux/api";
import { updateCommonData } from "../redux/commonSlice";
import { ROUTES } from "../utils/routes";

const EditInfluencer = () => {
  const { id } = useParams();
  const { data } = useGetUserQuery(id);
  const [updateUser, { isLoading, isSuccess }] = useUpdateUserMutation();
  const [snackbar, setSnackbar] = React.useState({
    message: "",
    severity: "success",
    open: false,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (data?.status == "SUCCESS") {
    const userData = data.result;
    var formData = {
      userId: userData.userId,
      name: userData.name,
      email: userData.email,
      phoneNumber: userData.phoneNumber,
      location: userData.location || [],
      gender: userData.gender?.capitalize(),
      profileNiche:
        userData.profileNiche && userData.profileNiche.length
          ? userData.profileNiche
            .filter((item) => item.option)
            .map((item) => item.option)
          : "",
      influencerType: userData.influencerType,
      followers: userData.followers,
      initialProfileNicheData: userData.profileNiche,
      platformUrls: userData.platformUrls,
      picture: userData.picture,
    };
  }

  React.useEffect(() => {
    if (isSuccess) {
      // setSnackbar({
      //   message: "Data edit successfully.",
      //   severity: "success",
      //   open: true,
      // });

      setTimeout(() => {
        dispatch(updateCommonData({
          message: "User successfully updated!",
          title: "Success",
          img: "/success.png",
          status: true
        }));
        navigate(ROUTES.USER_MANAGEMENT);
      }, 1);
    }
  }, [isLoading]);

  const handleClick = () => {
    navigate("/userManagement");
  };

  return (
    <Box>
      <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
      <Stack direction={"row"} mb={2} spacing={3}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Edit Influencer
          </Typography>
          <Stack
            className="backText"
            direction={"row"}
            gap={1}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
            onClick={handleClick}
          >
            <svg
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                stroke="#0077FF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                stroke="#0077FF"
                strokeWidth="1.5"
                strokeLinecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <Typography variant="h5" color={"primary"}>
              Back
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box

        sx={{
          backgroundColor: '#fff',
          transition: 'all .5s ease-in-out',
          position: 'relative',
          border: '1px solid rgba(170, 170, 170, 0.3)',
          borderRadius: '12px',
          boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
          overflow: 'hidden',
        }}
      >
        {data?.status && (
          <Form
            type={"update"}
            data={formData}
            isLoading={isLoading}
            onSubmit={updateUser}
          />
        )}
      </Box>
    </Box>
  );
};

export default EditInfluencer;
