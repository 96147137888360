
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { getFormatedDate } from '../../utils/common';
const DateRangePicker = (props) => {
    const { text, getSelectedDate, dateReset, style } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false);

    const handleChange = (dates) => {
        const [start, end] = dates;
        getSelectedDate(dates);
        setStartDate(start);
        setEndDate(end);
        setShowCalendar(false);

    };

    useEffect(() => {
        if (Object?.keys(dateReset)?.length === 0) {
            setStartDate(null);
            setEndDate(null);
        }
    }, [dateReset])


    return (
        <div>
            <DatePicker
                selected={startDate}
                style={style}
                onChange={handleChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline={showCalendar} // Show calendar based on state
                monthsShown={2}
                placeholderText={text}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput startDate={startDate} endDate={endDate} onClick={() => setShowCalendar(!showCalendar)} text={text} />}
            />
        </div>
    );
};

const CustomInput = ({ value, onClick, startDate, endDate, text }) => (
    <button className="custom-input" onClick={onClick}>
        {startDate && endDate ? `${getFormatedDate(startDate, 'DD/MM/yyyy')} - ${getFormatedDate(endDate, 'DD/MM/yyyy')}` : text}
    </button>
);

export default DateRangePicker;
