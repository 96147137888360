import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import * as React from "react";

export default function BasicSelect({
  values,
  handleChange,
  label = "",
  options,
  name,
  error,
  disabled,
  defaultValue = "",
  className = '',
  onBlur,
  required = false,
  multiple = false,
  style,
  renderValue,
  labelId,
  id,
}) {
  return (
    <FormControl fullWidth focused={false} className={`${className}`}>
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        multiple={multiple}
        disabled={disabled}
        name={name}
        label={label}
        value={values}
        onChange={handleChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
        style={style}
        renderValue={renderValue}
      >
        {options}
      </Select>
      <FormHelperText sx={{ color: "#d32f2f" }}>
        {error ? error : ""}
      </FormHelperText>
    </FormControl>
  );
}
