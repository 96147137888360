import React from "react"
import {
  Stack,
  Typography,
} from "@mui/material";

const SubscriptionMgt = () => {
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={3} mb={2}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            minHeight: '42px'
          }}
        >
          <Typography variant="h1" className="page-title">
            Subscription Management
          </Typography>
        </Stack>
      </Stack>

      <div className='card p-3 d-flex align-items-center justify-content-center' style={{ minHeight: '70vh' }}>
        <div className='comingSoon'>
          <h1>Coming Soon</h1>
        </div>
      </div>
    </>
  )
}

export default SubscriptionMgt;