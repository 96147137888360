import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 5,
  page: 0,
  sort_type: "asc",
  sort_key: "desc",
  search: "",
  totalCount: 0,
  totalPages: 0,
  list: [],
  filters: {},
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateDashboard: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetDashboard: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateDashboard, resetDashboard } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
