import { CloseSharp } from "@mui/icons-material";
import { Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bidAcceptedOrRejected } from '../../pages/campaigns/action';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function BidAcceptModal(props) {
    const { open, setOpen, bidDetails } = props;
    const handleClose = () => setOpen(false);


    const [details, setDetails] = useState();

    const dispatch = useDispatch();
    const {
        influencersBidDetails,
        campaignInfluencersList
    } = useSelector((state) => state.campaignSlice);


    useEffect(() => {
        if (influencersBidDetails?.attempts?.length > 0) setDetails(influencersBidDetails?.attempts[0]);
    }, [influencersBidDetails]);

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <Stack
                    className="px-4 py-3"
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h4"}
                        id="customized-dialog-title"
                    >
                        {bidDetails.bidText} {details?.bidName}
                    </Typography>
                    <div className="cursorPointer" onClick={handleClose}>
                        <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
                    </div>
                </Stack>
                <hr />
                <DialogContent className='p-4'>
                    <Typography variant='contained' component="p" gutterBottom>
                        Are you sure you want to  {bidDetails.bidText} the <b>{details?.bidName} Proposal</b> from the influencer {influencersBidDetails?.influencer?.name} of Rs. {details?.amount}/-?
                    </Typography>
                </DialogContent>
                <DialogActions className='border-0 px-4 py-4'>
                    <button className='btnSecondary' onClick={handleClose}>Cancel</button>
                    <button
                        className={bidDetails.bidText === "Reject" ? "btnDanger" : 'btnPrimary'}
                        onClick={() => {
                            setOpen(false)

                            dispatch(bidAcceptedOrRejected({ "status": bidDetails.bidStatus, influencerId: influencersBidDetails?.influencer?.id }, details?.id, influencersBidDetails.campaignId));
                        }} color="primary" variant="contained"> {bidDetails.bidText}</button>
                </DialogActions>
            </BootstrapDialog>

        </>
    );
}
