

const AccessDenied = () => {
    const styles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 0,
        fontFamily: "'Roboto', sans-serif",
        fontSize: '24px',
        color: '#333',
        textAlign: 'center'
    };
    return (
        <div style={styles}>
            <p>405 | Access Denied</p>
        </div>
    );
}

export default AccessDenied;
