import { Box, Pagination, Stack, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useRef, useState } from 'react';
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CommonModel from '../../components/dialog/Common';
import PaginationSelect from '../../components/select/pagination';
import { getFormatedDate } from '../../utils/common';
import { updateCampaign } from "../campaigns/campaignSlice";
import { updateUser } from "../users/userSlice";
import { getAllAuditListing, getCampaignAndinfluencersData } from './action';
import { updateAudit } from './auditSlice';




const AuditTrails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modelOpen, setModelOpen] = useState(false);
    const [jsonData, setJsonData] = useState('');
    const tableRef = useRef();
    const {
        graphData,
        limit,
        page,
        search,
        sort_key,
        totalCount,
        totalPages,
        listing
    } = useSelector((state) => state.auditSlice)

    useEffect(() => {
        dispatch(getCampaignAndinfluencersData());
        dispatch(getAllAuditListing());
    }, [])
    const handleChangePerPage = (event, value) => {
        dispatch(updateAudit({ limit: event.target.value, page: 0 }));
        dispatch(getAllAuditListing());
    };
    const handleChange = (event, value) => {
        dispatch(updateAudit({ page: value - 1 }));
        dispatch(getAllAuditListing());
    };
    useEffect(() => {
        const tableContainer = tableRef.current;

        const handleScroll = () => {
            if (tableContainer) {
                const { scrollTop, clientHeight, scrollHeight } = tableContainer;
                console.log("Scroll Position:", Math.ceil(scrollTop + clientHeight), scrollHeight, totalCount, listing.length);
                //&& totalCount > 0 && totalCount !== listing.length
                if (Math.ceil(scrollTop + clientHeight) >= scrollHeight - 1) {
                    //dispatch(updateAudit({ limit: limit + 20, page: 0 }));
                    //dispatch(getAllAuditListing());
                }
            }
        };

        if (tableContainer) {
            tableContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tableContainer) {
                tableContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [dispatch, limit]);


    const data = {
        options: {
            labels: graphData?.campaign?.map((v) => v.status) || [],
            chart: {
                width: '100%',
                dataLabels: {
                    formatter: function (val, opts) {
                        let name = opts.w.globals.labels[opts.seriesIndex];
                        let highlighted = (opts.seriesIndex === opts.dataPointIndex) ? "highlighted" : "";
                        return `<span class="${highlighted}">${name}</span>: ${val} <i class='fas fa-arrow-right'></i>`;
                    }
                },
                type: 'pie',
                background: '#f5f5f5',
                events: {
                    click: function (event, chartContext, config) {
                        if (config.dataPointIndex !== undefined) {
                            const dataPointIndex = config?.dataPointIndex;
                            const clickedLabel = chartContext?.w?.globals?.labels[dataPointIndex];

                            dispatch(updateCampaign({ filters: { status: clickedLabel } }));
                            navigate("/campaign-management");
                        }
                    }
                }
            },
            plotOptions: {
                pie: {
                    size: '100%',
                },
            },

        },

        series: graphData?.campaign?.map((v) => v.count) || [],
    };

    const influencerData = {
        options: {
            labels: graphData?.influencer?.map((v) => v.status) || [],
            chart: {
                width: '100%',
                dataLabels: {
                    formatter: function (val, opts) {
                        let name = opts.w.globals.labels[opts.seriesIndex];
                        let highlighted = (opts.seriesIndex === opts.dataPointIndex) ? "highlighted" : "";
                        return `<span class="${highlighted}">${name}</span>: ${val} <i class='fas fa-arrow-right'></i>`;
                    }
                },
                type: 'donut',
                background: '#f5f5f5',
                events: {
                    click: function (event, chartContext, config) {
                        if (config.dataPointIndex !== undefined) {
                            const dataPointIndex = config?.dataPointIndex;
                            const clickedLabel = chartContext?.w?.globals?.labels[dataPointIndex];

                            dispatch(updateUser({ status: clickedLabel }));
                            navigate("/userManagement");
                        }
                    }
                }
            },
            plotOptions: {
                pie: {
                    size: '100%',
                },

            },
            colors: ['#F7CB73', '#06C325', '#F14C5F'],
            fill: {
                colors: ['#F7CB73', '#06C325', '#F14C5F']
            },
        },
        series: graphData?.influencer?.map((v) => v.count) || [],
    };
    return <>
        <Stack direction="row" alignItems="center" mb={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography variant="h1" className="page-title">
                    Audit Trails
                </Typography>
            </Stack>
        </Stack>
        <div className='row g-4'>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                <Box className="card mb-0 pieChart" style={{ height: 'auto' }}>
                    <Typography variant="h4" px={3} pb={2} pt={2.5} className="page-title">Campaigns Status</Typography>
                    {/* <hr /> */}
                    <ReactApexChart
                        className="p-4"
                        options={data?.options}
                        series={data?.series}
                        type="pie"
                        height={300}
                        width="100%"

                    />
                </Box>
            </div>
            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                <Box className="card mb-0 pieChart" style={{ height: 'auto' }}>
                    <Typography variant="h4" px={3} py={2} className="page-title">Influencers Status</Typography>
                    {/* <hr /> */}
                    <ReactApexChart
                        className="p-4"
                        options={influencerData?.options}
                        series={influencerData?.series}
                        type="pie"
                        height={300}
                        width="100%"
                    />
                </Box>
            </div>
            <div className='col-12'>
                <Box className="card mb-0">
                    <Typography variant="h4" p={3} className="page-title">
                        Activity Logs
                    </Typography>
                    <hr />
                    <TableContainer sx={{ maxHeight: 400 }} stickyHeader ref={tableRef}>
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>User</TableCell>
                                    <TableCell align="left">Audit Log</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    {/* <TableCell align="left">Actions</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listing && listing?.length ? (
                                    listing?.map((row, index) => {
                                        return (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row?.user?.name}
                                                </TableCell>
                                                <TableCell align="left">{row.action}</TableCell>
                                                <TableCell align="left">{getFormatedDate(row.createdDate, 'YYYY-MM-DD HH:mm:ss')}</TableCell>
                                                {/* <TableCell align="left"> <span className="viewIcon" onClick={() => {
                                                setJsonData(JSON.stringify(row.jsonValue, null, 2))
                                                setModelOpen(true);
                                            }}>
                                                View
                                            </span></TableCell> */}
                                            </TableRow>

                                        )
                                    })) : (
                                    <TableRow colSpan={10}>
                                        <TableCell colSpan={10} style={{ textAlign: 'center' }}>

                                            <Typography
                                                color="inherit"
                                                variant="subtitle1"
                                                alignItems={"center"}
                                                component="h3"
                                            >
                                                No Data found!
                                            </Typography>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <Stack
                        direction="row"
                        alignItems={"center"}
                        justifyContent={"flex-end"}
                    >
                        <Box className="pagination-cover d-flex flex-wrap align-items-center justify-content-between py-4 px-4 w-100" gap={2}>
                            <Box className="d-flex align-items-center rowPerPage" gap={0}>
                                <span style={{ fontSize: '13px', color: 'rgba(0, 0, 0, 0.7)', fontWeight: '500' }}>Rows per page:</span>
                                <PaginationSelect handleChange={handleChangePerPage} page={limit} />
                            </Box>
                            <Box className="d-flex flex-wrap align-items-center pageSteps" gap={1}>
                                <Typography variant="subtitle2">
                                    {listing && listing.length > 0
                                        ? page === 0
                                            ? `0 - ${listing.length}`
                                            : `${limit * (page - 0) + 1}  - ${limit * (page - 0) + listing.length
                                            } `
                                        : "0 - 0"}{" "}
                                    &nbsp;of {totalCount} items
                                </Typography>
                                <Pagination
                                    count={Math.ceil(totalPages / limit) || 0}
                                    variant="outlined"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </Box>
            </div>
        </div>
        <CommonModel
            open={modelOpen}
            handleDialogClose={() => setModelOpen(false)}
            dialogTitle={"Audit Details"}
            dialogPara={<div style={{ padding: '10px', borderRadius: '5px' }}>

                <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', fontFamily: 'monospace', fontSize: '14px', textAlign: 'left' }}>

                    {jsonData}
                </pre>
            </div>}
            nvCancel={""}
            yesHandle={() => {
                setModelOpen(false)
            }}
        />
    </>
}
export default AuditTrails;