import { CloseSharp } from "@mui/icons-material";
import { FormLabel, Grid, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bidNegotiation } from '../../pages/campaigns/action';
import Schema from "../../schema";
import { RUPEES_SYMBOL, SUBMITTED } from '../../utils/constant';
import NumberInput from "../inputs/normalInput/number";
import CustomTextArea from '../inputs/textarea';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function BidNegotiate(props) {
    const { setBidOpen, bidOpen, setOpenmodel, negotation } = props;
    const handleClose = () => setBidOpen(false);

    const dispatch = useDispatch();

    const [details, setDetails] = useState();
    const {
        campaignInfluencersList,
        influencersBidDetails
    } = useSelector((state) => state.campaignSlice);

    useEffect(() => {
        if (influencersBidDetails?.attempts?.length > 0) setDetails(influencersBidDetails?.attempts[0]);
    }, [influencersBidDetails]);

    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={bidOpen}

            >
                <Stack
                    className="px-4 py-3"
                    direction="row"
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant={"h4"}
                        id="customized-dialog-title"
                    >
                        Negotiate {details?.bidName}
                    </Typography>
                    <div className="cursorPointer" onClick={handleClose}>
                        <CloseSharp sx={{ color: "#000", fontSize: '20px', fontWeight: '600' }} />
                    </div>
                </Stack>
                <hr />
                <DialogContent className='p-4'>
                    <Stack direction={"row"} gap={4} mb={2}>
                        <Typography variant='subtitle1'><span className="label">Original Budget:</span> {RUPEES_SYMBOL}{influencersBidDetails?.budget}</Typography>
                        <Typography variant='subtitle1'><span className="label text-capitalize">Bid:</span> {RUPEES_SYMBOL}{details?.amount || 0}</Typography>

                    </Stack>
                    <Stack direction={"row"} gap={4} mb={2}>
                        <Typography variant='subtitle1'><span className="label text-capitalize">Per-creator budget : </span> {RUPEES_SYMBOL}{influencersBidDetails?.budgetPerInfluencer || 0}</Typography>

                    </Stack>
                    <Formik
                        initialValues={{ amount: "", description: "" }}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validationSchema={Schema.BidNegotiate}
                        onSubmit={(values, { setSubmitting }) => {
                            let postData = {
                                bidName: "Your Bid -" + (negotation + 1),//details?.bidName,
                                influencerId: influencersBidDetails?.influencer?.id,
                                status: SUBMITTED,
                                amount: values.amount,
                                description: values.description,
                                campaignId: influencersBidDetails.campaignId
                            }

                            dispatch(bidNegotiation(postData, influencersBidDetails.id));
                            setBidOpen(false);
                            setOpenmodel(false)
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                            setFieldValue,
                            isValid,
                            dirty,
                        }) => {
                            return (
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }}
                                >

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel required>Negotiating Amount</FormLabel>
                                            <NumberInput
                                                name={"amount"}
                                                type={"number"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.amount}
                                                placeholder={"Enter Negotiating Amount"}
                                                onChange={handleChange}
                                                //onBlur={handleBlur}
                                                error={Boolean(touched.amount) && errors.amount}
                                                helperText={touched.amount && errors.amount}

                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={12} md={12} sm={12}>
                                            <FormLabel>Description</FormLabel>
                                            <CustomTextArea
                                                name={"description"}
                                                type={"text"}
                                                focused={false}
                                                label={("")}
                                                sizeval="medium"
                                                values={values.description}
                                                onChange={handleChange}
                                                placeholder="Enter your description"
                                                multiline={true}
                                            />

                                        </Grid>
                                    </Grid>
                                    <DialogActions>
                                        {/* <button className='btnSecondary' onClick={handleClose}>Cancel</button> */}
                                        <button className='btnPrimary' type="submit">Send</button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik >

                </DialogContent>

            </BootstrapDialog>

        </>
    );
}
