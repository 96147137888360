import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 20,
  page: 0,
  sort_type: "asc",
  sort_key: "desc",
  search: "",
  totalCount: 0,
  totalPages: 0,
  listing: [],
  campaigns: [],
  influencers: [],
  graphData: []
};

export const auditSlice = createSlice({
  name: "audit",
  initialState,
  reducers: {
    updateAudit: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetAudit: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateAudit, resetAudit } =
  auditSlice.actions;

export default auditSlice.reducer;
