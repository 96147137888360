import { Dialog, DialogContent, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { getFormatedDate } from '../../utils/common';
const FileHistory = (props) => {
    const { historyOpen, setHistoryOpen, data } = props;
    const handleClose = () => setHistoryOpen(false);


    return (
        <>
            <Dialog maxWidth={"md"} fullWidth open={historyOpen} onClose={handleClose}>
                <DialogContent className='p-0'>
                    <Grid container >
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>File Name</TableCell>
                                            <TableCell >Created Date</TableCell>
                                            <TableCell >Status</TableCell>
                                            <TableCell >Description</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.length > 0 ? data.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell><a href={row.location} rel="noreferrer" target='_blank'>{row.fileName}</a></TableCell>
                                                <TableCell  >{getFormatedDate(row.createdDate, "DD-MM-YYYY HH:mm:ss")}</TableCell>
                                                <TableCell  > <button className={row.status?.toLowerCase()}>{row.status}</button></TableCell>
                                                <TableCell >{row.description || "N/A"}</TableCell>
                                            </TableRow>
                                        )) : <TableRow><TableCell colSpan={4} align='center'  >No History Found</TableCell></TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                    </Grid>



                </DialogContent>
                <DialogActions className='px-4 pt-3 pb-4'>
                    <button
                        className='btnSecondary'
                        onClick={() => setHistoryOpen(false)}
                    >
                        Close
                    </button>
                </DialogActions>

            </Dialog >

        </>
    );
};

export default FileHistory;