import styled from "@emotion/styled";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import React from "react";
import Image from 'react-bootstrap/Image';
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import logoIcon from "../assets/images/logoIcon.svg";
import { ROUTES } from "../utils/routes";

import audits from "../assets/images/sideIcons/audits.png";
import brands from "../assets/images/sideIcons/brands.png";
import campaign from "../assets/images/sideIcons/campaign.png";
import company from "../assets/images/sideIcons/company.png";
import dashboard from "../assets/images/sideIcons/dashboard.png";
import privacy from "../assets/images/sideIcons/privacy.png";
import roles from "../assets/images/sideIcons/roles.png";
import subscription from "../assets/images/sideIcons/subscription.png";
import usermgt from "../assets/images/sideIcons/usermgt.png";
import users from "../assets/images/sideIcons/users.png";
import { setSidebar } from "../redux/reducer";


const sidebarData = [
  {
    title: "Dashboard",
    endpoint: "/",
    type: "dashboard",
    icon: dashboard,
    userType: ["admin", "brand", "company"],
    isActive: ["dashboard"]
  },
  {
    title: "Creator Management",
    endpoint: ROUTES.USER_MANAGEMENT,
    icon: usermgt,
    type: "users",
    userType: ["admin"],
    isActive: ["userManagement", 'createInfluencer', 'editInfluencer']
  },
  {
    title: "My Campaigns",
    endpoint: ROUTES.MY_CAMPAIGNS,
    icon: campaign,
    userType: ["brand", "company"],
    type: "myCampaign",
    isActive: ["my-campaign"]
  },
  {
    title: "Company Management",
    endpoint: ROUTES.COMPANIES,
    icon: company,
    type: "company",
    userType: ["admin",],
    isActive: ["companies", "company"]
  },

  {
    title: "Brands",
    endpoint: ROUTES.BRANDS_FOR_COMPANY,
    icon: brands,
    type: "brand",
    userType: ["brand", "company"],
    isActive: ["brands"]
  },
  {
    title: "Campaign Management",
    endpoint: ROUTES.CAMPAIGN_MANAGEMENT,
    icon: campaign,
    type: "campaign",
    userType: ["admin"],
    isActive: ["campaign"]
  },
  // {
  //   title: "My Campaigns",
  //   endpoint: ROUTES.MY_CAMPAIGNS,
  //   icon: <FaUsersCog />,
  //   type: "campaign mgt",
  //   userType: ["brand", "admin"],
  // },
  {
    title: "Admin User",
    endpoint: ROUTES.ADMIN_USERS,
    icon: users,
    type: "adminUsers",
    userType: ["admin"],
    isActive: ["adminUsers"]
  },
  {
    title: "Roles & Rights Mgt.",
    endpoint: ROUTES.ROLES_AND_RIGHTS,
    icon: roles,
    userType: ["admin"],
    type: "roleAndRights",
    isActive: ["roles"]
  },
  {
    title: "Subscription Mgt.",
    endpoint: ROUTES.SUBSCRIPTION_MGT,
    icon: subscription,
    type: "subscription",
    userType: ["admin"],
    isActive: ["subscription"]
  },
  {
    title: "Audit Trail",
    endpoint: ROUTES.AUDIT_TRAILS,
    icon: audits,
    type: "auditTrail",
    userType: ["admin"],
    isActive: ["auditTrails"]
  },
  {
    title: "Privacy policy",
    endpoint: ROUTES.PRIVACY_POLICY,
    icon: privacy,
    type: "privacyPolicy",
    userType: ["admin"],
    isActive: ["privacyPolicy"]
  },
  // {
  //   title: "Search Influencers",
  //   endpoint: "/search-Influencers",
  //   icon: search,
  //   type: "searchInfl",
  //   userType: ["admin", "brand", "company"],
  // },
];

const HoveredList = styled(List)(({ theme, sx }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100vh",
  overflowY: "auto",
  transition: `"all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}"`,
  boxShadow: "5px 4px 16px rgba(0,0,0,0.04)",
  overflowX: "hidden",
  ...sx,
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  margin: "0px 3px",
  borderRadius: "15px",
  padding: "13px 8px",
  border: "1px solid rgba(255,255,255)",
  color: "#6C7692",
  position: "relative",
  "&:hover, &.active": {
    background: theme.palette.primary.main,
    color: "white",
    "& .listItemIcon": {
      color: "white",
    },
  },
}));

const Sidebar = () => {
  const theme = useTheme();
  const states = useSelector((state) => state.app);
  const location = useLocation();
  const dispatch = useDispatch();



  const userType = JSON.parse(localStorage.getItem("user"));
  let sideBarJson = [];
  if (userType?.userType === "company") {
    sideBarJson = {
      brands: { edit: 1, view: 1, create: 1, delete: 1 },
      users: { edit: 1, view: 1, create: 1, delete: 1 },
      myCampaigns: { edit: 1, view: 1, create: 1, delete: 1 },
      dashboard: { edit: 1, view: 1, create: 1, delete: 1 },
      searchInculencer: { edit: 1, view: 1, create: 1, delete: 1 },
    }
  }
  else {
    sideBarJson = sidebarData.reduce((acc, item) => {
      const type = item.type;
      acc[type] = { edit: 1, view: 1, create: 1, delete: 1 };
      return acc;
    }, {});
  }
  const handleToggleSidebar = (e) => {
    e.preventDefault();
    dispatch(setSidebar());
  };

  return (
    states.permissions && ( //states.permissions.users
      <HoveredList
        className={`sidebar ${!states.sidebar ? 'activeSidebar' : ''}`}
        sx={{
          maxWidth: states.sidebarWidth,
          width: "100%",
          backgroundColor: "background.paper",
          zIndex: 1000,
        }}
      >
        <Link className="sidebarLogo">
          <Box className="fullLogo" component="img" src={logo}></Box>
          <Box className="logoIcon" component="img" src={logoIcon}></Box>
        </Link>
        {/* <div className="closeSidebar" onClick={handleToggleSidebar} >
          <KeyboardDoubleArrowLeftIcon />
        </div> */}
        {states.permissions && sidebarData.map(
          (item, index) =>  //states.permissions
            states.permissions?.[item.type]?.view !== 0 &&
            item?.userType?.includes(
              JSON.parse(localStorage.getItem("user"))?.userType
            ) && (
              <ListItem key={index}>
                <StyledListItem
                  className={item.isActive.includes(location?.pathname?.split("/")[1]) ? "active" : ""}
                  disableRipple
                  key={item.endpoint}
                  component={NavLink}
                  to={item.endpoint}
                >

                  <ListItemIcon className="listItemIcon">
                    {/* {item.icon} */}
                    <Image src={item.icon} className='img-fluid' alt="" />
                  </ListItemIcon>
                  <ListItemText className="tabText m-0" disableTypography primary={item.title} />
                </StyledListItem>
              </ListItem>
            )
        )}


        <ListItem
          className="versionControl"
          sx={{
            paddingX: "16px",
            paddingY: "16px",
            display: "flex",
            bottom: "0px",
            justifyContent: "center",
            position: "absolute",
            transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
          }}
        >
          {/* <Box>Version 0.1</Box> */}
        </ListItem>
      </HoveredList>
    )
  );
};

export default Sidebar;
