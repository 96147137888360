import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommonData } from '../../redux/commonSlice';



export default function Alert() {
    const { message, title, img, status } = useSelector((state) => state.commonSlice)

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(updateCommonData({
            status: false
        }));
    };
    useEffect(() => {
        let id = setTimeout(() => {
            dispatch(updateCommonData({
                status: false
            }));
        }, 3000);
        return () => {
            clearTimeout(id);
        }
    }, [status])


    return (
        <>

            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={status}
                fullWidth
                maxWidth="xs"
                className='modalToast'
                style={{
                    borderRadius: '16px !important'
                }}
            >

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent className='smallModal' style={{ padding: '40px' }}>
                    <Typography variant="p" component="div" className="text-center" mt={2} mb={2}><img src={img} alt="success" style={{ width: '70px', height: '70px' }} /></Typography>
                    <Typography variant="h1" component="h1" className="text-center" mb={0.5} style={{ color: '#000', fontSize: '28px' }}>{title}</Typography>
                    <Typography variant='p' component="p" className="text-center mb-0" style={{ color: '#000', fontSize: '16px', marginTop: 4 }} gutterBottom>{message}</Typography>
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Save changes
                    </Button>
                </DialogActions> */}
            </Dialog>
        </>
    );
}