import * as yup from "yup";
export const loginValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  captcha: yup.string("Please complete the CAPTCHA verification").required("Please complete the CAPTCHA verification"),
});

export const roleValidationSchema = yup.object({
  roleName: yup.string("Enter role").required("Role Name is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
});

export const adminUserValidationSchema = yup.object({
  name: yup.string("Enter User Name").required("User Name is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  roleId: yup.string("Enter Roles").required("Role is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/,
      "Password must contain at least one uppercase letter, one number, and one special character"
    )
    .required("Password is required"),
});

export const adminUserChangePasswordValidationSchema = yup.object({
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z0-9!@#$%^&*]{8,}$/,
      "Password must contain at least one uppercase letter, one number, and one special character"
    )
    .required("Password is required"),
  newPassword: yup.string()
    .required("Confirm Password is Required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const createInfluencerValidationSchema = yup.object({
  name: yup.string("Enter Name").required("Name is Required"),
  phoneNumber: yup.string().trim().required("Phone is required").min(10, "Phone number must be at least 10 characters"),

  // phoneNumber: yup
  //   .string()
  //   .matches(/^[6-9]\d{9}$/, "Phone number is not valid")
  //   .required("Phone number is required"),
  // location: yup.mixed().required("Location is Required"),
  location: yup.array()
    .of(yup.mixed().required("Location is required"))
    .min(1, "Location is required"),
  gender: yup.string("Enter Gender").required("Gender is Required"),
  profileNiche: yup.array()
    .of(yup.mixed().required("Niche is required"))
    .min(1, "Niche is required"),
  // influencerType: yup
  //   .string("Enter Influencer Type")
  //   .required("Influencer Type is Required"),
  followers: yup.string("Enter Followers").required("Followers is Required"),
  // profileNiche: yup.array().min(1, "At Least One Profile Niche is Required"),
});

export const createCampaignValidationSchema = yup.object({
  campaignName: yup
    .string("Enter Campaign Name")
    .required("Campaign Name is required"),
  campaignType: yup
    .string("Enter Campaign Type")
    .required("Campaign Type is Required"),
  brandName: yup.string("Enter Brand Name").required("Brand Name is required"),
  paymentType: yup
    .string("Enter Payment Type")
    .required("Payment Type is required"),
  duration: yup.string("Enter Duration").required("Duration is required"),
  productLink: yup
    .string("Enter Product Link ")
    .required("Product Link is required"),
  campaignDetails: yup
    .string("Enter Campaign Details")
    .required("Campaign Details is required"),
  websiteLink: yup
    .string("Enter Website Link")
    .required("Website Link is required"),
  postedOn: yup.string("Enter Posted On").required("Posted On is required"),
  deliverables: yup
    .string("Enter Deliverables")
    .required("Deliverables is required"),
  hashtag: yup.string("Enter hashtag").required("hashtag is required"),
  gender: yup.string("Enter Gender").required("Gender is Required"),
  ageGroup: yup.string("Enter Age Group").required("Age Group is Required"),
  followers: yup.string("Enter Followers").required("Followers is Required"),
});
