import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  OutlinedInput
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormik } from "formik";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import logoIcon from "../assets/images/logoIcon.svg";
import Alert from '../components/dialog/Alert';
import CustomizedSnackbars from "../components/snackbar";
import { getParamMasterList } from '../layout/action';
import { useLoginMutation } from "../redux/api";
import { getAuthTokenFromLS } from '../utils/common';
import { CAPTCHA_SITE_ID } from '../utils/constant';
import { ROUTES } from "../utils/routes";
import { loginValidationSchema } from "../utils/schema";
import { updateUser } from './users/userSlice';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "success",
    open: false,
  });

  const defaultCreds = { email: "", password: "", captcha: "" };

  const [login, { isLoading, data: userData }] = useLoginMutation();

  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: defaultCreds,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => await login({ ...values, userType: "admin" }),
  });

  React.useEffect(() => {
    if (getAuthTokenFromLS() == null || getAuthTokenFromLS() == undefined) {
      localStorage.clear();
    }

    dispatch(getParamMasterList());
    let otpStatus = userData?.result?.otp || false;
    if (otpStatus) {
      navigate("/verify", { state: { userData: userData?.result?.userData } });
      //  return false;
    } else {
      if (userData?.status === "SUCCESS") {
        setSnackbar({
          message: userData.message,
          severity: "success",
          open: true,
        });
        dispatch(updateUser({ modelOpen: false, brandCount: userData?.result?.userData?.brandCount }))
        navigate(ROUTES.DASHBOARD);
        localStorage.setItem("token", userData.result.jwtToken);
        let roleId = userData.result.roleId;

      }
    }
    if (userData?.status === "FAILURE") {
      setSnackbar({
        message: userData.message,
        severity: "error",
        open: true,
      });
      return;
    }
  }, [isLoading]);
  const handleRecaptchaChange = (token) => {
    formik.handleChange({
      target: {
        name: "captcha",
        value: token
      }
    })

  };
  return (
    <>
      <div className="loginPage signupbg wrap">
        <CustomizedSnackbars msgData={snackbar} setMsgData={setSnackbar} />
        <div className="col-xxl-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-start">
              <div className="leftText">
                <div className="maintagline ">
                  <span>Register to get</span>More with Inflyx
                </div>
                <ul className="reg_pointer">
                  <li>🧠 Intelligent Matchmaking</li>
                  <li>🕓 Historical Insights</li>
                  <li>📝 Post Campaign Analysis</li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center justify-content-start">
              <div className="formSide w-100 ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
                <div className="formcvr">
                  <div className="logoIcon text-center mb-3">
                    <Image src={logoIcon} alt="Logo Icon" />
                  </div>
                  <div className="tagline text-center">Welcome to Inflyx</div>
                  <h1 className="text-center mb-3">
                    Login to your <span className='textGradient'>Account</span>
                  </h1>
                  <form className="frm pt-3" onSubmit={formik.handleSubmit}>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <FormLabel htmlFor="email" required focused={false}>Email address</FormLabel>
                      <OutlinedInput
                        id="email"
                        type="email"
                        name="email"
                        placeholder="Enter your email address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      {formik.touched.email && (
                        <FormHelperText variant="standard" error>
                          {formik.errors.email}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                      <FormLabel htmlFor="password" required focused={false}>Password</FormLabel>
                      <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="Enter your password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        endAdornment={
                          <IconButton
                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        }
                      />
                      {formik.touched.password && (
                        <FormHelperText variant="standard" error>
                          {formik.errors.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Form.Group
                      className="mb-3 d-flex align-items-center justify-content-between"
                      controlId="formBasicCheckbox"
                    >
                      <Form.Check type="checkbox" label="Remember Me" className='mb-0' />
                      <div className="text-center">
                        <Link className='text-decoration-none' style={{ fontSize: '14px', fontWeight: '500' }} to={`${ROUTES.FORGOT_PASSWORD}`}>Forgot Password?</Link>
                      </div>
                    </Form.Group>
                    <ReCAPTCHA
                      sitekey={CAPTCHA_SITE_ID}  //working key
                      //size="invisible"
                      onChange={handleRecaptchaChange}
                    // badge="inline"
                    // action="submit"
                    />
                    {formik.touched.captcha && (
                      <FormHelperText variant="standard" error>
                        {formik.errors.captcha}
                      </FormHelperText>
                    )}
                    <button className="btnPrimary w-100 mt-2" type="submit">
                      Login
                    </button>
                    <p className="text-center redirectLink mt-4 fs14">
                      Don't have an account?{" "}
                      <Link to={`/${ROUTES.COMPANY_SIGNUP}`} className='text-decoration-none'>Sign Up</Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Alert /></>
  );
};

export default Login;
