import Button from "react-bootstrap/Button";

const FileUpload = (props) => {

    const { onChange, fileName } = props;

    return <>
        <div
            style={{
                border: '1px solid #aaa',
                borderRadius: '8px',
                padding: '5px'
            }}
        >
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '10px', pointer: "cursor" }}>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    style={{ borderRadius: 5, background: "rgba(170, 170, 170, 0.5)", color: " rgba(0, 0, 0, 0.7)", fontSize: '14px', fontWeight: '500', lineHeight: '20px' }}
                >
                    Upload file
                </Button>
                <input
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,

                    }}
                    class="cursorPointer"
                    type="file"
                    onChange={onChange}
                />
                {fileName && <p className="m-0">{fileName}</p>}
            </div>
        </div>
        {/* <small style={{ fontSize: '13px', fontWeight: '400', color: '#6C7692', display: 'inline-block', lineHeight: '18px' }}>Supported dimensions (pixels) are 180x180 and 300x300.</small> */}

    </>
}
export default FileUpload;