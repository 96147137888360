import { combineReducers } from "redux";
import globalLoaderSlice from "../components/backdrop/backdropSlice";
import auditSlice from "../pages/auditTrails/auditSlice";
import brandSlice from "../pages/brands/brandSlice";
import campaignSlice from "../pages/campaigns/campaignSlice";
import companySlice from "../pages/company/companySlice";
import dashboardSlice from "../pages/dashboard/dashboardSlice";
import privacySlice from "../pages/privacy/privacySlice";
import userSlice from "../pages/users/userSlice";
import { api } from "./api";
import commonSlice from "./commonSlice";
import { firebaseApi } from "./firebaseApi";
import appReducer, { appSlice } from "./reducer";
const rootReducer = combineReducers({
    companySlice,
    brandSlice,
    campaignSlice,
    commonSlice,
    userSlice,
    privacySlice,
    auditSlice,
    dashboardSlice,
    globalLoaderSlice,
    [appSlice.name]: appReducer,
    [api.reducerPath]: api.reducer,
    [firebaseApi.reducerPath]: firebaseApi.reducer,
});

export default rootReducer;