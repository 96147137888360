import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Chip, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bidAcceptedOrRejected } from '../../pages/campaigns/action';
import { BID_ACCEPT, BID_REJECT, CAMPAIGN_TYPES, NEGOTATION, REBID, RUPEES_SYMBOL, SUBMITTED } from '../../utils/constant';
import { ROUTES } from '../../utils/routes';
import BidAcceptModal from './BidAccept';
import BidNegotiate from './BidNegotiate';
const BiddingDetails = (props) => {
    const { openModal, setOpenmodel } = props;
    const handleClose = () => setOpenmodel(false);
    const [bidOpen, setBidOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [negotation, setNagotation] = useState(0);
    const dispatch = useDispatch();


    const {
        influencersBidDetails,
        campaignInfluencersList
    } = useSelector((state) => state.campaignSlice);
    const [bidDetails, setBiddetails] = useState({ bidStatus: "", bidText: "" });

    useEffect(() => {
        setNagotation(influencersBidDetails?.attempts?.filter((v) => v.actionType === NEGOTATION)?.length);
    }, [influencersBidDetails]);

    return (
        <>
            <Dialog maxWidth={"lg"} fullWidth open={openModal} onClose={handleClose}>
                <DialogContent className='forScrollbarDesign' style={{ padding: '0px' }}>
                    <div className='dialogFixedHeader'>
                        <Stack direction={"row"} px={3} py={2.5} className='d-flex flex-wrap justify-content-between align-items-center w-100'>
                            <Typography width={"90%"} component="div" className='d-flex align-items-center' gap={1}>
                                <div class="MuiAvatar-root MuiAvatar-circular css-1nn861f-MuiAvatar-root"><img alt="Remy Sharp" style={{ height: '40px', width: '40px', borderRadius: "40px" }} src={influencersBidDetails?.influencer?.picture || "/profile.jpg"} className="MuiAvatar-img" /></div>
                                <Typography variant='h4' component="h4" >
                                    <b>{influencersBidDetails?.influencer?.name || "N/A"}</b>
                                </Typography>&nbsp;
                                <Typography component="div" className='d-flex' style={{ gap: '5px' }} >
                                    <FacebookOutlinedIcon size="small" color='primary' />
                                    <InstagramIcon size="small" color='error' />
                                </Typography>
                            </Typography>
                            <Typography width={"10%"} variant='contained' component="div" style={{ padding: '0px' }}>
                                <a className='text-decoration-none' style={{ fontSize: '14px', fontWeight: '500' }} href={`${ROUTES.USER_DETAILS}${influencersBidDetails?.influencer?.id}`} rel="noreferrer" target='_blank'>Profile Details</a>
                            </Typography>
                            <Box className="industNiches">
                                {influencersBidDetails?.influencer?.profileNiche?.map((v) => <Chip className='chipsItem' label={v.option} size='small' color="primary" />)}
                            </Box>
                        </Stack>
                        <hr />
                        <Stack direction={"row"} gap={1} px={3} py={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box className="apprvdInflu" sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }} gap={16}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Followers: </span>  {influencersBidDetails?.influencer?.followers || 0}K
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Location:</span>   {influencersBidDetails?.influencer?.location?.toString() || "N/A"}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Brands Endorse:</span> 4
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    <span className="label">Type:</span>  {influencersBidDetails?.type}
                                </Typography>
                            </Box>
                        </Stack>
                        <hr />

                        <Stack direction={"row"} >
                            {influencersBidDetails?.type !== CAMPAIGN_TYPES.BARTER && <Typography px={3} py={2} variant="subtitle1">
                                <span className="label">Original Budget  :   </span> {RUPEES_SYMBOL}{influencersBidDetails?.budget || 0}
                            </Typography>}
                            <Typography px={3} py={2} variant="subtitle1">
                                <span className="label">Total collaboration value per influencer  :   </span> {RUPEES_SYMBOL}{influencersBidDetails?.budgetPerInfluencer || 0}
                            </Typography>
                        </Stack>
                    </div>
                    <div className='py-3 px-4 processScrolable'>
                        {influencersBidDetails?.type !== CAMPAIGN_TYPES.BARTER && <div className='row bidFlows'>
                            {influencersBidDetails?.type !== CAMPAIGN_TYPES.BARTER && influencersBidDetails?.attempts?.length > 0 ? [...influencersBidDetails?.attempts]?.sort((a, b) => a.id - b.id)?.map((v, i) =>

                                <div key={v.id} className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                    <div className='rounds'>
                                        {i % 2 === 0 ? "Round-" + (Math.ceil((i) / 2) + 1) : ''}
                                        {/* {`${(influencersBidDetails?.attempts?.length - i)}Process-${Math.ceil((influencersBidDetails?.attempts?.length - i) / 2)}`} */}
                                    </div>
                                    <div className='row'>
                                        <div className='col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <div className='chatType'>
                                                <div className='userImg1'>
                                                    <div class="MuiAvatar-root MuiAvatar-circular css-1nn861f-MuiAvatar-root"><img alt="Remy Sharp" style={{ height: '60px', width: '60px', borderRadius: "60px" }} src={v.actionType === "bid" ? (influencersBidDetails?.influencer?.picture || "/profile.jpg") : (influencersBidDetails?.brandImage || "/profile.jpg")} className="MuiAvatar-img" /></div>

                                                </div>
                                                <div className='chatArea'>
                                                    <div className='amtHighlight'>
                                                        <span className="" style={{ textTransform: "capitalize" }}>{v.actionType === "bid" ? "Rebid for" : "You Negotiated for"}  </span> {v.amount}
                                                    </div>
                                                    <div className='chatDesc'>
                                                        <label>Description</label>
                                                        <div className='brief'>
                                                            {v.description || "N/A"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            ) :

                                false && <Typography variant='subtitle1' >
                                    <span className="label">Budget Per Influencer : </span>
                                    {RUPEES_SYMBOL}{influencersBidDetails.budgetPerInfluencer || 0}
                                </Typography>
                            }



                        </div>}
                    </div>

                    {/*  <Stack direction={"row"} px={3} py={2.5} gap={3} sx={{ background: '#f7f7f7' }}>

                        <Box gap={2.5} className="apprvdInflu" sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="subtitle1">
                                <span className="label">Original Budget: </span> {influencersBidDetails.budget || 0}
                            </Typography>
                            {influencersBidDetails.type !== CAMPAIGN_TYPES.BARTER ?
                                <>
                                    {influencersBidDetails?.attempts?.map((v) => {
                                        return <Typography key={v.id} variant='subtitle1'><span className="label">{v.bidName}:</span> {v.amount}</Typography>
                                    })}

                                </>

                                : <Typography variant='subtitle1'><span className="label">Budget Per Influencer:</span> {influencersBidDetails.budgetPerInfluencer || 0}</Typography>
                            }
                        </Box>
                    </Stack>
                    <hr />
                   {influencersBidDetails.type !== CAMPAIGN_TYPES.BARTER &&
                        <Grid container spacing={4} p={3} className="apprvdInflu">
                            {influencersBidDetails?.attempts?.map((v) => {
                                return <>
                                    <Grid key={v.id} item xs={12} lg={6} md={6} sm={12}>
                                        <Typography variant='subtitle1'><span style={{ fontWeight: '500', color: '#000', display: 'block' }}>{v.bidName}{" "}:</span>{"  "}
                                            <span style={{ fontWeight: "500", display: 'block', fontSize: '14px', lineHeight: '20px', fontFamily: 'Inter', color: "rgba(0, 0, 0, 0.7)" }}>{v.description || "N/A"}</span>
                                        </Typography>
                                    </Grid>
                                </>
                            })}

                        </Grid>
                    } */}
                </DialogContent>
                <hr />
                <DialogActions className='btnLgGrp' style={{ padding: '24px 24px' }}>
                    <button className="btnSecondary" onClick={handleClose} color="primary" variant="contained">Close</button>
                    {/* influencersBidDetails?.status === REBID */}
                    {[REBID, SUBMITTED].includes(influencersBidDetails?.status) &&
                        <>
                            <button
                                className='btnPrimary'
                                onClick={() => {
                                    if (influencersBidDetails?.type !== CAMPAIGN_TYPES.BARTER) {
                                        setOpenmodel(false);
                                        setOpen(true)
                                        setBiddetails({ bidStatus: BID_ACCEPT, bidText: "Accept" });
                                    } else {
                                        dispatch(bidAcceptedOrRejected({ "status": BID_ACCEPT, influencerId: influencersBidDetails?.influencer?.id }, influencersBidDetails['attempts'][0].id, influencersBidDetails.campaignId));
                                        setOpenmodel(false);
                                    }

                                }} color="primary" variant="contained">Accept {influencersBidDetails?.type !== CAMPAIGN_TYPES.BARTER && (influencersBidDetails?.attempts?.length > 0 ? influencersBidDetails?.attempts[0]?.bidName : "Bid")}</button>

                            {influencersBidDetails?.type !== CAMPAIGN_TYPES.BARTER &&
                                <button className={influencersBidDetails?.attempts?.length >= 5 ? 'btnDisabled' : 'btnlightgrey'} disabled={influencersBidDetails?.attempts?.length >= 5} onClick={() => setBidOpen(true)} color="primary" variant="contained">Negotiation-{negotation + 1} </button>
                            }
                            <button
                                className='btnDanger'
                                onClick={() => {
                                    if (influencersBidDetails?.type !== CAMPAIGN_TYPES.BARTER) {
                                        setBiddetails({ bidStatus: BID_REJECT, bidText: "Reject" });
                                        setOpenmodel(false);
                                        setOpen(true)
                                    } else {
                                        dispatch(bidAcceptedOrRejected({ "status": BID_REJECT, influencerId: influencersBidDetails?.influencer?.id }, influencersBidDetails['attempts'][0].id, influencersBidDetails.campaignId));
                                        setOpenmodel(false);
                                    }

                                }} sx={{ background: "#FE5D26" }} color="primary" variant="contained">Reject Influencer</button>
                        </>
                    }
                </DialogActions>
            </Dialog>
            <BidAcceptModal setOpen={setOpen} open={open} bidDetails={bidDetails} />
            <BidNegotiate bidOpen={bidOpen} setBidOpen={setBidOpen} setOpenmodel={setOpenmodel} negotation={negotation} />
        </>
    );
};

export default BiddingDetails;
