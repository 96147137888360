import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  limit: 10,
  page: 0,
  sort_type: "desc",
  sort_key: "id",
  search: "",
  totalCount: 0,
  totalPages: 0,
  formData: {},
  step: 1,
  userListing: [],
  influencersFilter: encodeURIComponent(JSON.stringify({})),
  invitedInfluencers: [],
  listing: [],
  filters: {},
  myCampaigns: [],
  campaignInfluencersList: [],
  influencersBidDetails: {},
  scriptDetails: [],
  journeyId: 0,
  referenceContentUrl: "",
  address: {},
  productDetails: {},
  nicha: [],
  sugType: "create",
  productDropdown: [],
  suggestedInfluencers: [],
  searchedInfluencers: [],
  searchedInfluencersInvitedIds: [],
  searchedInfluencersCheckboxIds: [],
  invitedIds: [],
  listType: 0,
  appliedInfluencerId: "",
  influencersList: [],
  advanceFilter: {},
  userId: 0,
  brandLogo: "",
  sendButtonClicked: false,
  progressBar: 0,
  deliverablesSection: 0,
  detailsSection: 0,
  addtionalSection: 0
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    updateCampaign: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetCampaign: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCampaign, resetCampaign } =
  campaignSlice.actions;

export default campaignSlice.reducer;
