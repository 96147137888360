import { FormLabel, Grid, Stack, Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logoIcon from "../../assets/images/logoIcon.svg";
import Alert from "../../components/dialog/Alert";
import NormalInput from "../../components/inputs/normalInput";
import NumberInput from "../../components/inputs/normalInput/number";
import Toaster from "../../components/toaster/toaster";
import Schema from "../../schema";
import { ROUTES } from "../../utils/routes";
import { companySignup } from "./action";

const CompanySignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const initialValues = {
    companyName: "",
    phoneNumber: "",
    contactPersonName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phoneCountryCode: "+91"
  }

  return (
    <div className="loginPage signupbg wrap">
      <div className="col-xxl-8 col-xl-9 col-lg-10 col-md-12 col-sm-12 col-12 mx-auto">
        <div className="row">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-start">
            <div className="leftText">
              <div className="maintagline">
                <span>Register to get</span>More with Inflyx
              </div>
              <ul className="reg_pointer">
                <li>🧠 Intelligent Matchmaking</li>
                <li>🕓 Historical Insights</li>
                <li>📝 Post Campaign Analysis</li>
              </ul>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12  d-flex align-items-center justify-content-start">
            <div className="formSide w-100 ps-xxl-5 ps-xl-5 ps-lg-5 ps-md-0 ps-sm-0 ps-0">
              <div className="formcvr">
                <div className="logoIcon text-center mb-3">
                  <Image src={logoIcon} alt="Logo Icon" />
                </div>
                <h1 className="text-center mb-3">
                  Company <span className="textGradient">Profile Creation</span>
                </h1>
                <Toaster />
                <Formik
                  initialValues={initialValues}
                  validationSchema={Schema.CompanySignup}
                  enableReinitialize={true}
                  onSubmit={(values, { resetForm }) => {
                    delete values.confirmPassword;
                    dispatch(companySignup(values, navigate));
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    isValid,
                    setFieldError,
                    dirty,
                  }) => {
                    return (
                      <Form>
                        <Grid className="frm" container>
                          <Grid item xs={12} lg={12} md={12} sm={12}>
                            <FormLabel required focused={false}>Email</FormLabel>

                            <NormalInput
                              name={"email"}
                              type={"text"}
                              focused={false}
                              label={("")}
                              sizeval="medium"
                              values={values.email}

                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(touched.email) && errors.email}
                              helperText={touched.email && errors.email}
                            />
                          </Grid>
                          <Grid item xs={12} lg={12} md={12} sm={12}>
                            <FormLabel required focused={false}>Username</FormLabel>
                            <NormalInput
                              name={"contactPersonName"}

                              type={"text"}
                              focused={false}
                              label={("")}
                              sizeval="medium"
                              values={values.contactPersonName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(touched.contactPersonName) && errors.contactPersonName}
                              helperText={touched.contactPersonName && errors.contactPersonName}
                            />
                          </Grid>
                          <Grid item xs={12} lg={12} md={12} sm={12}>
                            <FormLabel required focused={false}>Company Name</FormLabel>
                            <NormalInput
                              name={"companyName"}

                              type={"text"}
                              focused={false}
                              label={("")}
                              sizeval="medium"
                              values={values.companyName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(touched.companyName) && errors.companyName}
                              helperText={touched.companyName && errors.companyName}
                            />
                          </Grid>

                          <Grid item xs={12} lg={12} md={12} sm={12}>
                            <FormLabel required focused={false}>Phone Number</FormLabel>
                            <NumberInput
                              name={"phoneNumber"}
                              type={"number"}

                              focused={false}
                              label={("")}
                              sizeval="medium"
                              values={values.phoneNumber}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  handleChange(e);
                                } else {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={handleBlur}
                              error={Boolean(touched.phoneNumber) && errors.phoneNumber}
                              helperText={touched.phoneNumber && errors.phoneNumber}
                            />
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item xs={12} lg={6} md={6} sm={12}>
                              <FormLabel required focused={false}>Password</FormLabel>
                              <NormalInput
                                name={"password"}
                                type={showPassword ? "text" : "password"}
                                focused={false}

                                label={("")}
                                sizeval="medium"
                                values={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.password) && errors.password}
                                helperText={touched.password && errors.password}
                                handleViewPassword={() => setShowPassword((prev) => !prev)}
                                isEndAdornment={true}
                                showPassword={showPassword}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6} md={6} sm={12}>
                              <FormLabel required focused={false}>Confirm Password</FormLabel>
                              <NormalInput
                                name={"confirmPassword"}
                                type={showConfirmPassword ? "text" : "password"}
                                focused={false}

                                label={("")}
                                sizeval="medium"
                                values={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.confirmPassword) && errors.confirmPassword}
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                isEndAdornment={true}
                                handleViewPassword={() => setShowConfirmPassword((prev) => !prev)}
                                showPassword={showConfirmPassword}
                              />
                            </Grid>
                          </Grid>
                          <Grid item mt={2} xs={12} lg={12} md={12} sm={12} container justifyContent="flex-end">
                            <Stack className="w-100" direction={"row"} spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                              <p className="m-0 p-0 redirectLink fs14">
                                Already have an account?
                                <Link to={ROUTES.LOGIN} style={{ textDecoration: 'none', marginLeft: '5px' }}>
                                  Login
                                </Link>
                              </p>

                              <Typography component="div" className="d-flex align-items-center" gap={1}>
                                <button
                                  className="btnPrimary"
                                  type="submit"
                                // disabled={!(isValid && dirty)}
                                >
                                  Continue
                                </button>
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert />
    </div >
  );
};

export default CompanySignup;
