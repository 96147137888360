
export const endpoints = {
    paramMaster: "config/parameter-master",
    addCampaign: "campaign/createCampaign",
    createCampaignBudget: "campaign/createCampaignBudget",
    createCampaignDate: "campaign/createCampaignDate",
    getAllInfluencers: "admin/getAllUsers",
    getAllCampaign: "getAllCampaign",
    campaign: "campaign/",
    deleteCreator: "admin/user",
    addCampaignToInfluencer: "Influencer/invite",
    deleteCreator: "admin/user",
    addCompany: "/company/create",
    brands: "brands",
    getaudittrail: "getaudittrail",
    company: "company/getAllCompany",
    myCampaigns: "/brand/getMyCampaign",
    getCampaignInfluencerEndpoint: "/campaign/",
    bidAcceptedOrRejectedEndpoint: "Influencer/attempt/",
    bidNegotiatedEndpoint: "Influencer/apply",
    campaignContentJourney: "campaignContentJourney/",
    presignedurl: "largeUpload",
    updateCompany: "company",
    updateAdmin: 'admin/updateAdmin/',
    createBrand: 'brand/create',
    getBrandById: 'brand',
    updateBrand: 'brand',
    getUserAndCampainInsights: 'getUserAndCampainInsights',
    updateCampaignStatus: 'admin/updateCampaignStatus',
    getCompanyById: '/company',
    updateUserStatus: "admin/updateAdmin/",
    campaignContentProductStatus: "campaignContentProductStatus",
    getAllInfluencersSuggestions: "Influencer/search",
    getUserSocialDetails: "Influencer/socialByInfluencerId/",
    userDetails: "admin/getUser/",
    getInvitedInfluencers: "getInvitedInfluencers/",
    forgotPassword: "company/forgotPassword",
    resetPassword: "company/resetPassword",
    ims: "ims",
    policy: "terms-condition",
    dashboardInfluencerList: "admin/getSearchUsers",
    updateCompanyQandA: "company-flow",
    otpVerification: "company/login/otp-verify",
    getContents: "social-media/post/",
    getAllBrands: "social-media/brand/",
    getBrandPosts: "social-media/brand-post"
}