
const apiBaseUrl = () => {
  const { hostname } = window.location;
  if (hostname.includes("ims-dev-fe.houseoforange.co.in")) { // for UAT Server
    return "https://ims-dev-api.houseoforange.co.in/v1/";
  }
  if (hostname.includes("app.inflyx.co")) { // for Production server
    return "https://ims-api-backend.inflyx.co/v1/";
  }
  if (hostname.includes("ims-fb-dev-fe.houseoforange.co.in")) { // for FB DEV Server
    return "https://ims-fb-dev-api.houseoforange.co.in/v1/";
  }
  return "https://ims-int-dev-api.houseoforange.co.in/v1/"; // for Int-dev server
}

export const BASE_URL = apiBaseUrl();

export const FIREBASE_BASE_URL = "http://localhost:3002/v1";

export const citiesInIndia = [
  " Pan India",
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Hyderabad",
  "Ahmedabad",
  "Chennai",
  "Kolkata",
  "Surat",
  "Pune",
  "Jaipur",
  "Lucknow",
  "Kanpur",
  "Nagpur",
  "Visakhapatnam",
  "Indore",
  "Thane",
  "Bhopal",
  "Patna",
  "Vadodara",
  "Ghaziabad",
  "Ludhiana",
  "Coimbatore",
  "Agra",
  "Madurai",
  "Nashik",
  "Vijayawada",
  "Faridabad",
  "Meerut",
  "Rajkot",
  "Kalyan-Dombivali",
  "Vasai-Virar",
  "Varanasi",
  "Srinagar",
  "Aurangabad",
  "Dhanbad",
  "Amritsar",
  "Navi Mumbai",
  "Allahabad",
  "Ranchi",
  "Howrah",
  "Jabalpur",
  "Gwalior",
  "Vijayawada",
  "Jodhpur",
  "Raipur",
  "Kota",
  "Guwahati",
  "Chandigarh",
  "Solapur",
  "Hubli-Dharwad",
  "Bareilly",
  "Moradabad",
  "Mysore",
  "Gurgaon",
  "Aligarh",
  "Jalandhar",
  "Tiruchirappalli",
  "Bhubaneswar",
  "Salem",
  "Warangal",
  "Guntur",
  "Bhiwandi",
  "Saharanpur",
  "Gorakhpur",
  "Bikaner",
  "Amravati",
  "Noida",
  "Jamshedpur",
  "Bhilai",
  "Cuttack",
  "Firozabad",
  "Kochi",
  "Nellore",
  "Bhavnagar",
  "Dehradun",
  "Durgapur",
  "Asansol",
  "Rourkela",
  "Nanded",
  "Kolhapur",
  "Ajmer",
  "Akola",
  "Gulbarga",
  "Jamnagar",
  "Ujjain",
  "Loni",
  "Siliguri",
  "Jhansi",
  "Ulhasnagar",
  "Nellore",
  "Jammu",
  "Sangli-Miraj & Kupwad",
  "Belgaum",
  "Mangalore",
  "Ambattur",
  "Tirunelveli",
  "Malegaon",
  "Gaya",
  "Jalgaon",
  "Udaipur",
  "Maheshtala",
];


export const SOCIAL_MEDIA = ["Instagram", "Linkedin", "Youtube", "Facebook"];
export const SOCIAL_PLATFORM = ["Instagram", "Youtube"];
export const STATUS = ["DRAFT", "PENDING", "LIVE", "REJECTED", 'UNPUBLISHED'];
export const INF_TYPE = ["Micro", "Nano", "Mid Tier", "Macro"];
export const CREATOR_GENDER = ["Male", "Female", "Others"];
export const CREATOR_SOURCE = ["Admin", "Creator", "Bulk Upload"];
export const GENDER = ["Male", "Female", "LGBTQ", "Any"];
export const FOLLOWERS = ["Nano (2K-10K Followers)", "Micro (10K-25K Followers)", "Mid Tier (25K-100K Followers)", "Macro (100K-250K Followers)"];
export const FOLLOWERS_ARRAY = [{ label: "2K-10K (Nano)", value: "2K-10K", newValue: "Nano" },
{ label: "10K-25K (Micro)", value: "10K-25K", newValue: "Micro" }, { label: "25K-100K (Mid Tier)", value: "25K-100K", newValue: "Mid Tier" }, { label: "100K-250K (Macro)", value: "100K-250K", newValue: "Macro" }];
export const AGE_GROUP = ["18-25", "25-35", "35-45", "45+"];
export const NUMBER_VALIDATION = /^[0-9]+(\.[0-9]+)?$/;
export const MAX_10DIGIT = /^\d{10}$/;

export const PASSWORD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}/; //One capital and one small and one number and one special character 


export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
}

export const QUANTITY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export const INFLUENCER_NICHE = "influencerNiche";
export const DELIVERABLES_QTY = "deliverablesQty";
export const DELIVERABLES_POST = "deliverablesPost";
export const KPIS = "kpis";
export const PLATEFORMS = "platforms";
export const FONT_PREFERENCES = "fontPreferences";
export const INSTAGRAM_TYPE = "instagram";
export const YOUTUBE_TYPE = "youtube";
export const INSTAGRAM_TYPE_WITH_CAPS = "Instagram";
export const YOUTUBE_TYPE_WITH_CAPS = "Youtube";
export const FAILURE = "FAILURE";



export const INFLUENCER_TYPE = "influencerType";

export const INSTAGRAM = ["Instagram Image", "Instagram Reels", " Instagram Carousel"];
export const YOUTUBE = ["Yotube Video", "Youtube Shorts"];
export const CAMPAIGN_TYPE = [{ image: "", type: "paid", title: "Paid Campaign", description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo." },
{ image: "", type: "barter", title: "Barter", description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo." },
{ image: "", type: "affiliate", title: "Performance/Affiliate Campaign", description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo." }];

export const ACHIEVE_CAMPAIGN = [{ image: "", title: "Launch Campaign" }, { image: "", title: "Awareness Campaign" },
{ image: "", title: "UGC Campaign" }, { image: "", title: "Product Reviews" },
{ image: "", title: "Testimonials" }, { image: "", title: "Long Term Association" },
{ image: "", title: "Contest/ Challenge" }
];
export const KPI_CAMPAIGN = [{ image: "11", title: "Performance Campaign" }, { image: "22", title: "Affiliate Campaign" }];
export const CAMPAIGN_TYPES = {
  PAID: "paid",
  BARTER: "barter",
  AFFILIATE: "affiliate"
}
export const SOCIAL_PLATEFORM = {
  FB: "facebook",
  INSTAGRAM: "instagram",
  YOUTUBE: "youtube"
}

export const USER_STATUS_FILTER = ["Pending", 'Approved', 'Rejected'];

export const USER_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED"
}

export const SUCCESS = "SUCCESS";
export const WEBSITE_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
export const sortedCities = citiesInIndia.sort();
export const BID_ACCEPT = "ACCEPTED";
export const BID_REJECT = "REJECTED";
export const REBID = "REBID";
export const SUBMITTED = "SUBMITTED";
export const NEGOTATION = "negotation";
export const REVIEW = "REVIEW";
export const DRAFT = "DRAFT";
export const PENDING = "PENDING";
export const UNPUBLISHED = "UNPUBLISHED";
export const REJECTED = "REJECTED";
export const NEGOTATION_NEW = "NEGOTATION";
export const DELIVERED = "DELIVERED";
export const SHIPPED = "SHIPPED";
export const LIVE = "LIVE";
export const COMPANY = "company"
export const ADMIN = "admin"

export const getColor = (status) => {
  switch (status) {
    case 'ACCEPTED':
      return '#0077FF  !important';
    case 'REJECTED':
      return '#EF3F3F !important';
    case 'REBID':
      return '#FFC04B  !important';
    case 'SUBMITTED':
      return '#FFC04B  !important';
    case 'NEGOTIATION':
      return '#FFC04B  !important';
    case 'REVIEW':
      return "#6C7692B2  !important"
    default:
      return '#6C7692B2';
  }
}

export const PRODUCT_STATUS = ["IN PROCESS", "SHIPPED"];


export const getFixedValue = (nums) => {

  let num = parseFloat(nums);
  if (isNaN(num)) {
    return 0;
  }

  const units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
  let unitIndex = -1;
  if (parseFloat(num) === undefined || parseFloat(num) === 0) {
    return 0;
  };



  let absNum = Math.abs(num);

  while (absNum >= 1000 && unitIndex < units.length - 1) {
    absNum /= 1000;
    unitIndex++;
  }

  if (unitIndex >= 0) {
    return (num / Math.pow(1000, unitIndex + 1)).toFixed(2).replace(/\.0+$/, '') + units[unitIndex];
  }

  return num !== undefined || num !== 0 || num !== "0" ? num?.toFixed(0) : 0;
};




export const gettwoDigitFixedValue = (price) => price?.toFixed(2);


export const getDecimal = (price) => {


  if (!isNaN(price) && price !== undefined) {
    price = parseFloat(price);
    const formattedPrice = price.toFixed(2); // Formats the number to two decimal places
    return formattedPrice
  } else {
    return 0;
  }
}

export const getDateformatUsingSeconds = (seconds) => {

  const timestamp = seconds;

  const date = new Date(timestamp);

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export const getDaysDifference = (startDate, endDate) => {
  const startMillis = new Date(startDate).getTime();
  const endMillis = new Date(endDate).getTime();

  const differenceMillis = endMillis - startMillis;

  const daysDifference = differenceMillis / (1000 * 60 * 60 * 24);

  return Math.round(daysDifference);
}
export const MAX_FILE_SIZE = 2 * 1024 * 1024;

export const CAPTCHA_SITE_ID = "6Lc4c-spAAAAAHDzNuazjayc5lsuGtedmIGvMm0O"; //"6LdzaespAAAAAA3WufJzYeJX7FNQwAsDgAwGnCDm"; // working key

export const SOCKET_EVENT = {
  COMPANY_CREATED: "COMPANY_CREATED",
  CAMPAIGN_CREATED: "CAMPAIGN_CREATED",
  CAMPAIGN_APPROVED: "CAMPAIGN_APPROVED",
  BID_UPDATED: "BID_UPDATED",
  CONTENT_POST: "CONTENT_POST"
}
export const RUPEES_SYMBOL = "₹";

export const TWO_FACTOR_AUTH_VALUE = ["Select", "SMS", "Email", "Both"]

export const COUNTRY_JSON = {
  "AF": "Afghanistan",
  "AX": "Åland Islands",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "AndorrA",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua and Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia and Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo",
  "CD": "Congo, The Democratic Republic of the",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "CI": "Cote D'Ivoire",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FK": "Falkland Islands (Malvinas)",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island and Mcdonald Islands",
  "VA": "Holy See (Vatican City State)",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran, Islamic Republic Of",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle of Man",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KP": "Korea, Democratic People'S Republic of",
  "KR": "Korea, Republic of",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Lao People'S Democratic Republic",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libyan Arab Jamahiriya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MK": "Macedonia, The Former Yugoslav Republic of",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia, Federated States of",
  "MD": "Moldova, Republic of",
  "MC": "Monaco",
  "MN": "Mongolia",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "AN": "Netherlands Antilles",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestinian Territory, Occupied",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russian Federation",
  "RW": "RWANDA",
  "SH": "Saint Helena",
  "KN": "Saint Kitts and Nevis",
  "LC": "Saint Lucia",
  "PM": "Saint Pierre and Miquelon",
  "VC": "Saint Vincent and the Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome and Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "CS": "Serbia and Montenegro",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia and the South Sandwich Islands",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard and Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syrian Arab Republic",
  "TW": "Taiwan, Province of China",
  "TJ": "Tajikistan",
  "TZ": "Tanzania, United Republic of",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad and Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks and Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UM": "United States Minor Outlying Islands",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela",
  "VN": "Viet Nam",
  "VG": "Virgin Islands, British",
  "VI": "Virgin Islands, U.S.",
  "WF": "Wallis and Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe"
}
export const capitalizeFirstLetter = (str) => {
  if (!str) return str; // Check if the string is not empty
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}