import { Box, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ACHIEVE_CAMPAIGN, CAMPAIGN_TYPE, CAMPAIGN_TYPES, KPI_CAMPAIGN } from "../../../utils/constant";
import { updateCampaign } from "../campaignSlice";

import {
    Stack
} from "@mui/material";
import { useState } from "react";

const Type = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [active, setActive] = useState("");
    const {
        formData
    } = useSelector((state) => state.campaignSlice);
    const { state } = useLocation();


    return (
        <>
            <Stack direction={"column"} gap={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography className="page-title" variant="h1">Create New Campaign</Typography>
                </Stack>
                <div className="row g-4 campCat">
                    {CAMPAIGN_TYPE.map((v, i) => {

                        return <>
                            <div key={v.type} className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12" p={3} lg={4} md={4} sm={4} xs={12} bgcolor={"white"} sx={{ cursor: "pointer", borderRadius: "16px", border: state?.type === v.type ? "2px solid #aaa" : "2px solid #AAAAAA" }} onClick={() => {
                                dispatch(updateCampaign({ formData: { ...formData, type: v.type, mode: "add" }, step: 2 }))
                                // navigate("/campaign/sub-type");
                            }} >
                                <div className={`bg-white catType p-4  ${v.type === formData.type ? "active" : ""}`} style={{ cursor: "pointer", borderRadius: "16px", border: state?.type === v.type ? "2px solid #aaa" : "2px solid #aaa" }}>
                                    <Typography variant="h6" mb={3} component="h6">

                                        <img src={`/${v.type}.png`} alt={v.title} width={60} height={60} style={{ borderRadius: '100px' }} />

                                        {/* {v.type === formData.type && <Typography variant="span" ><Chip avatar={<Avatar alt="Selected" src="/right.png" />} label="Selected" color="primary" /></Typography>} */}

                                    </Typography>
                                    <Typography variant="h4" mb={2} component="h4">
                                        {v.title}
                                    </Typography>
                                    <Typography variant="p" component="p" fontFamily={"Inter"} fontSize={14}>
                                        {v.description}
                                    </Typography>
                                </div>
                            </div >
                        </>
                    })}


                </div>

                {formData.type && <Grid container alignItems="center" sx={{
                    bgcolor: "white",

                }} gap={2}>
                    <Grid item xs={12} lg={12} md={12} sm={12} pt={3} pl={3}>
                        <Typography variant="h4" component="h4">
                            What do you want to achieve from this campaign?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} md={12} sm={12} >
                        <Grid container spacing={4} p={3}>
                            {[CAMPAIGN_TYPES.PAID, CAMPAIGN_TYPES.BARTER].includes(formData.type) && ACHIEVE_CAMPAIGN.map((v, i) => {
                                return <Grid item key={i} className="showhandCursor" xs={3} lg={3} md={3} sm={3} sx={{

                                    textAlign: "center"
                                }} onClick={() => {
                                    setActive(() => {
                                        return v.title
                                    })
                                    dispatch(updateCampaign({ formData: { ...formData, demand: active || v.title }, step: 3 }))
                                    navigate("/campaign/info")
                                }}  >
                                    <Box sx={{ border: "1px solid #aaa", borderRadius: 16 }} className={active === v.title ? "campaignActive" : ""}>
                                        {active === v.title && <img src={`/achiveCampaign/active.png`} alt={v.title} />}
                                        <Typography variant="h6" mb={1} component="h6">
                                            <img src={`/achiveCampaign/${i + 1}.png`} alt={v.title} />
                                        </Typography>
                                        <Typography variant="h4" component="h4">
                                            {v.title}
                                        </Typography>
                                    </Box>
                                </Grid>
                            })}

                            {[CAMPAIGN_TYPES.AFFILIATE].includes(formData.type) && KPI_CAMPAIGN.map((v, i) => {
                                return <Grid item key={i} xs={3} lg={3} md={3} sm={3} className="showhandCursor" sx={{

                                    textAlign: "center"
                                }} onClick={() => {
                                    setActive(() => {
                                        return v.title
                                    })
                                    dispatch(updateCampaign({ formData: { ...formData, demand: active || v.title }, step: 3 }))
                                    navigate("/campaign/info")
                                }}  >
                                    <Box sx={{ border: "1px solid #D9D9D9", borderRadius: 4 }} className={active === v.title ? "campaignActive" : ""}>
                                        {active === v.title && <img src={`/achiveCampaign/active.png`} alt={v.title} />}
                                        <Typography variant="h6" mb={1} component="h6">
                                            <img src={`/${v.image}.png`} alt={v.title} />
                                        </Typography>

                                        <Typography variant="h5" component="h5" p={1}>
                                            {v.title}
                                        </Typography>
                                    </Box>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid >}
            </Stack >

        </>
    );

}
export default Type;