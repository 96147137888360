import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ACHIEVE_CAMPAIGN, CAMPAIGN_TYPES, KPI_CAMPAIGN } from "../../../utils/constant";
import { updateCampaign } from "../campaignSlice";

const SubType = () => {
    const navigate = useNavigate();
    const [active, setActive] = useState("");
    const dispatch = useDispatch();
    const { formData } = useSelector((state) => state.campaignSlice);
    const { state } = useLocation();
    useEffect(() => {
        if (formData?.demand) {
            setActive(formData?.demand);
        }
    }, [formData])
    return <>
        <Stack direction={"column"} gap={2}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    minHeight: '42px'
                }}
            >
                <Typography className="page-title" variant="h1">Create New Campaign</Typography>
            </Stack>
            <Box

                sx={{
                    backgroundColor: '#fff',
                    transition: 'all .5s ease-in-out',
                    position: 'relative',
                    border: '1px solid rgba(170, 170, 170, 0.3)',
                    borderRadius: '12px',
                    boxShadow: '0px 5px 5px 0px rgba(82, 63, 105, 0.05)',
                    overflow: 'hidden',
                }}>
                <Grid container alignItems="center" gap={2}>
                    <Grid item xs={12} lg={12} md={12} sm={12} p={3}>
                        <Typography variant="h4" component="h4">
                            What do you want to achieve from this campaign?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={10} md={12} sm={12} p={3} pt={0}>
                        <Grid container spacing={2}>
                            {[CAMPAIGN_TYPES.PAID, CAMPAIGN_TYPES.BARTER].includes(formData.type) && ACHIEVE_CAMPAIGN.map((v, i) => {
                                return <Grid item key={i} className="showhandCursor" xs={6} xl={2} lg={3} md={3} sm={3} sx={{
                                    bgcolor: "white",
                                    textAlign: "center"
                                }} onClick={() => setActive(v.title)}  >
                                    <Box sx={{ border: "1px solid #aaa", borderRadius: 16 }} className={active === v.title ? "campaignActive" : ""}>
                                        {active === v.title && <img src={`/achiveCampaign/active.png`} alt={v.title} />}
                                        <Typography variant="h6" mb={1} component="h6">
                                            <img src={`/achiveCampaign/${i + 1}.png`} alt={v.title} />
                                        </Typography>
                                        <Typography variant="h4" component="h4">
                                            {v.title}
                                        </Typography>
                                    </Box>
                                </Grid>
                            })}

                            {[CAMPAIGN_TYPES.AFFILIATE].includes(formData.type) && KPI_CAMPAIGN.map((v, i) => {
                                return <Grid item key={i} xs={6} lg={3} md={3} xl={2} sm={3} className="showhandCursor" sx={{
                                    bgcolor: "white",
                                    textAlign: "center"
                                }} onClick={() => setActive(v.title)}  >
                                    <Box sx={{ border: "1px solid #D9D9D9", borderRadius: 4 }} className={active === v.title ? "campaignActive" : ""}>
                                        {active === v.title && <img src={`/achiveCampaign/active.png`} alt={v.title} />}
                                        <Typography variant="h6" mb={1} component="h6">
                                            <img src={`/${v.image}.png`} alt={v.title} />
                                        </Typography>

                                        <Typography variant="h5" component="h5" p={1}>
                                            {v.title}
                                        </Typography>
                                    </Box>
                                </Grid>
                            })}
                        </Grid>
                    </Grid>
                </Grid >
                <hr />
                <Grid item p={3} xs={12} lg={12} md={12} sm={12} gap={1} container justifyContent="flex-end">
                    <button
                        className="btnSecondary"
                        onClick={() => {
                            //dispatch(updateCampaign({ formData: { ...formData, demand: active }, step: 2 }))
                            navigate("/campaign/type")
                        }}
                    >
                        Back
                    </button>
                    <button
                        className="btnPrimary"
                        disabled={active === ""}
                        onClick={() => {
                            dispatch(updateCampaign({ formData: { ...formData, demand: active }, step: 3 }))
                            navigate("/campaign/info")
                        }}
                    >
                        Next
                    </button>
                </Grid>
            </Box>
        </Stack>

    </>
}
export default SubType;