import { Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useSelector } from 'react-redux';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));



const CustomizedProgressBars = ({ bar }) => {
    const { deliverablesSection, detailsSection, addtionalSection } = useSelector(
        (state) => state.campaignSlice
    );
    let total1 = bar.deliverablesSection + bar.detailsSection + bar.addtionalSection;// 
    let total = parseInt(deliverablesSection) + parseInt(detailsSection) + parseInt(addtionalSection);


    return (
        <> <Stack spacing={2} sx={{ flexGrow: 1, ml: 5 }}>
            <BorderLinearProgress variant="determinate" value={total == 3 ? 100 : total * 33} />

        </Stack>
            <Typography
                variant="subtitle2"
                ml={2}
            >
                {total}/3 completed
            </Typography></>

    );
}
export default React.memo(CustomizedProgressBars)