import { Box, Grid, MenuItem, Stack } from "@mui/material";
import { createFilterOptions } from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AutoSuggestion from "../../components/autocomplete";
import CustomAuto from "../../components/autocomplete/CustomAuto";
import BasicSelect from "../../components/select";
import { getSearchdata, setSearchdata } from "../../utils/common";
import { getInfluencerListFordashboard } from "./action";
import { updateDashboard } from "./dashboardSlice";
const useStyles = makeStyles({
    autocompleteListbox: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '15px !important',
        gap: '8px',
    },
    autocompleteOption: {
        padding: '0px !important',
        background: 'transparent !important',
    },
    scrollbar: {
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#ccc',
            borderRadius: '5px',
        },
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

const filter = createFilterOptions();
const SearchBox = () => {

    const [influencerNiche, setInfluencerNiche] = useState([]);
    //  const [plateforms, setPlateform] = useState([]);
    const { plateforms, niches } = useSelector((state) => state.commonSlice);



    const [value, setValue] = useState({ platform: "Instagram", categories: [] });

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        let data = getSearchdata();
        let cat = data?.categories ? data?.categories?.map((v) => {
            return { title: v };
        }) : []
        setValue({ platform: data?.platform || "Instagram", categories: cat });
    }, []);
    console.log("value", value)
    return <>
        <Formik
            initialValues={value ? value : { platform: "Instagram", categories: [] }}
            enableReinitialize={true}
            validateOnChange={true}
            //validationSchema={Schema.Step4}
            onSubmit={(values, { setSubmitting }) => {
                const jsonString = JSON.stringify(values);
                setSearchdata(jsonString);
                values.categories = values?.categories?.map((v) => v.title)
                dispatch(
                    updateDashboard({
                        filters: values,
                        search: ""
                    }),
                );
                dispatch(getInfluencerListFordashboard());
                navigate("/dashboard/listing");
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
                isValid,
                dirty,
            }) => {

                return (
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}
                    >

                        <Grid container mb={6}>
                            <Grid item className='mx-auto' xs={12} xl={8} lg={8} md={12} sm={12} >


                                <Stack className="searchBlockInflu" direction="row">
                                    <Box width="18%">
                                        <BasicSelect
                                            className="searchPlatform"
                                            required={false}
                                            name="platform"
                                            label=""
                                            defaultValue={values.platform}
                                            values={values.platform}
                                            handleChange={handleChange}
                                            options={plateforms.map((item) => (
                                                <MenuItem value={item} key={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                            style={{}}
                                        />
                                    </Box>

                                    <Box className="searchText" style={{ width: 'calc(100% - 235px)', paddingLeft: '10px', fontSize: '15px' }}>
                                        {/* <i>Search name, categories, topics, bio keywords or give prompt ✨</i> */}
                                        <CustomAuto type="list" handleChange={(e, newValue) => {
                                            handleChange({
                                                target: { name: "categories", value: newValue },
                                            });
                                        }} values={values} />
                                        {false && <AutoSuggestion
                                            multiple={true}
                                            limitTags={2}
                                            disableClearable={true}
                                            value={values.categories}
                                            className="auto-chips-cover"
                                            label={""}
                                            onChange={(_e, newValue) => {
                                                handleChange({
                                                    target: { name: "categories", value: newValue },
                                                });
                                                // if (typeof newValue === 'string') {
                                                //     // timeout to avoid instant validation of the dialog's form.
                                                //     console.log("111")
                                                // } else if (newValue && newValue.inputValue) {
                                                //     console.log("222")
                                                // } else {
                                                //     console.log("3333")
                                                //     handleChange({
                                                //         target: { name: "categories", value: newValue },
                                                //     });
                                                // }
                                                // if (newValue.length === 0) {
                                                //     console.log("sssssssss");
                                                // } else {
                                                //     handleChange({
                                                //         target: { name: "categories", value: newValue },
                                                //     });
                                                // }

                                            }}

                                            filterOptions={(options, params) => {
                                                let filtered = filter(options, params);
                                                const { inputValue } = params;
                                                const isExisting = options.some((option) => inputValue === option);
                                                if (inputValue !== '' && !isExisting) {
                                                    filtered = [...filtered, `Influencers with the name "${inputValue}"`];
                                                    // filtered.push({
                                                    //     inputValue: params.inputValue,
                                                    //     //title: `Add "${inputValue}"`,
                                                    // });
                                                }
                                                return filtered;
                                            }}
                                            key={values?.categories?.length}
                                            defaultValue={values.categories}
                                            id="free-solo-dialog-demo"
                                            size="small"
                                            name="search"
                                            placeholder={values?.categories?.length > 0 ? "Add More.." : "Categories, topics, bio keywords or give prompt #anything"}

                                            options={niches}
                                            isOptionEqualToValue={(option, value) => {
                                                if (
                                                    value === "" ||
                                                    option === "" ||
                                                    value === option
                                                )
                                                    return option;
                                            }}
                                            getOptionLabel={(option) =>
                                                option || "no_option_found"
                                            }

                                        // renderOption={(props, option) => {
                                        //     return (
                                        //         < >
                                        //             {props.id === "search-option-0" && <Grid item xs={12} lg={12} md={12} sm={12} mb={2}>
                                        //                 <Typography component="h4" variant="h4" display="block" p={2} pt={0}>
                                        //                     Search name, categories, topics, bio keywords or give prompt ✨
                                        //                 </Typography>
                                        //                 <hr />
                                        //             </Grid>}

                                        //             <Grid sx={{ display: "flex", flexWrap: 'wrap' }} {...props}>
                                        //                 <Chip

                                        //                     avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                        //                     label={option}
                                        //                     color="default"
                                        //                 />


                                        //             </Grid>

                                        //         </>
                                        //     );
                                        // }}
                                        // renderTags={(value, getTagProps) => (
                                        //     <Grid>
                                        //         {value.slice(0, 2).map((option, index) => (
                                        //             <Grid item key={option}>
                                        //                 <Chip
                                        //                     label={option}
                                        //                     {...getTagProps({ index })}
                                        //                     avatar={<Avatar alt={option} src="/avtar.jpg" />}
                                        //                 />
                                        //             </Grid>
                                        //         ))}
                                        //         {value.length > 2 && (
                                        //             <Grid item>
                                        //                 <Typography>+ {value.length - 2} more</Typography>
                                        //             </Grid>
                                        //         )}
                                        //     </Grid>
                                        // )}
                                        />}

                                    </Box>
                                    <Box>
                                        <button
                                            className="searchBtn"
                                            type="submit"
                                        >
                                            Generate ✨

                                        </button>
                                    </Box>
                                </Stack>


                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    </>
}
export default SearchBox;