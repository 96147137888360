import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { Alert } from "../../utils/Alert";
import { api } from "../../utils/api";
import { encodedURI, removeEmptyValues } from "../../utils/common";
import { endpoints } from "../../utils/endpoints";
import { updateDashboard } from "./dashboardSlice";


export const getInfluencerListFordashboard = () => {
    return (dispatch, getState) => {
        const { limit, page, filters, search } = getState().dashboardSlice;
        const filter = encodedURI(removeEmptyValues(filters))
        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.dashboardInfluencerList,
            `?limit=${limit}&offset=${page * limit}&filter=${filter}`,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(
                    updateDashboard({
                        list: respData.data.result.data, totalCount: respData.data.result.count, totalPages: respData.data.result.count
                    })
                );
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}